import type { RootState } from "store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  BillingDetailsResponse,
  ChangePasswordConfig,
  Configuration,
  FailedResponse,
  PaymentApi,
  Register,
  RegisterApi,
  RegisterInterest,
  ResetPasswordApi,
  UserBalance,
  UserConfigPublic,
  Users,
  UsersApi,
  UsersGet200Response,
  UsersInvitePost200Response,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import {
  inviteUserFailure,
  userAuthenticationFailure,
  userAvatarDeleteFailure,
  userAvatarDeleteSuccess,
  userAvatarUpdateSuccess,
  userAvatarUploadFailure,
  userPasswordChangeFailure,
  userPasswordChangeSuccess,
  userUpdateBillingChangeSuccess,
} from "utils/toasts/messages";
import { setLocalStorageUserData, getLocalStorageUserData } from "utils/data/user";
import axios from "axios";
import Cookies from "js-cookie";

export interface AuthFormData {
  tenantName?: string;
  email?: string;
  userFullName?: string;
  userCognitoId?: string;
  industry?: string;
  no_of_employees?: string;
  annual_revenue?: string;
  password1?: string;
  password2?: string;
  objective?: string;
  code?: string;
}

export interface RegisterFormData {
  name: string;
  email: string;
  password: string;
  verify_password?: string;
  objective?: string;
}

// interface Balance {
//   amount: string;
//   currency: string;
//   billDate: string;
//   dueDate: string;
//   invoice: string;
//   invoiceUrl: string;
// }

interface PaginationPayload {
  page?: number;
  limit?: number;
}

// TODO - Lint Errors
//
// const getAvatar = async (api: UsersApi, UUID: number) => {
//   const response = await api.usersAvatarUserUUIDGet(UUID.toString());
//   return response.data ? `data:image/png;base64,${String(response.data)}` : "";
// };

export const registerUser = createAsyncThunk(
  "users/register",
  async (
    args: {
      payload: {
        name: string;
        email: string;
        password: string;
        clientId: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.registerUserPost(payload);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const verifyUser = createAsyncThunk(
  "users/verify",
  async (
    args: {
      payload: {
        email: string;
        verification_code: string;
        clientId: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.registerUserVerifyPost(payload)).data;
      const { jwt, refresh, jwt_expiry, refresh_expiry } = response ?? {};
      if (jwt && refresh_expiry && jwt_expiry && refresh) {
        Cookies.set("token", jwt, { expires: new Date(jwt_expiry) });
        Cookies.set("refresh_token", refresh, { expires: new Date(refresh_expiry) });
      }
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const resendOtp = createAsyncThunk(
  "users/resendOtp",
  async (
    args: {
      payload: {
        email: string;
        client_id: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.registerUserResendOtpPost(payload);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const loginUser = createAsyncThunk(
  "users/login",
  async (
    args: {
      payload: {
        email: string;
        password: string;
        client_id: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.registerLoginPost(payload)).data;
      const { jwt, refresh, jwt_expiry, refresh_expiry } = response;
      if (jwt && refresh_expiry && jwt_expiry && refresh) {
        Cookies.set("token", jwt, { expires: new Date(jwt_expiry) });
        Cookies.set("refresh_token", refresh, { expires: new Date(refresh_expiry) });
      }
      return response;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const { status, data } = err.response;
        return rejectWithValue({ status, data });
      }
      return rejectWithValue({ status: 500, data: "Something went wrong" });
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  "users/requestResetPassword",
  async (
    args: {
      payload: {
        email: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new ResetPasswordApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.registerRequestResetPasswordPost(payload);
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      throw err;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (
    args: {
      payload: {
        reset_token: string;
        new_password: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new ResetPasswordApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.registerResetPasswordPost(payload)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const createTenant = createAsyncThunk(
  "users/createTenant",
  async (
    args: {
      payload: Register;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const accessToken = Cookies.get("token");
      const api = new RegisterApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );

      const response = await api.registerPost(payload);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const registerInterest = createAsyncThunk(
  "users/registerInterest",
  async (
    args: {
      payload: RegisterInterest;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;
      const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.registerInterestPost(payload);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          return rejectWithValue({ message: errorMessage });
        }
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUsers = createAsyncThunk<UsersGet200Response, PaginationPayload | undefined>(
  "users/getUsers",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.usersGet(arg?.page, arg?.limit)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUsersFilter = createAsyncThunk<Users, string | undefined>(
  "users/getUsersFilter",
  async (search, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.usersFilterGet(search)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getLoggedInUserAvatar = createAsyncThunk(
  "users/getLoggedInUserAvatar",
  async (_, thunkAPI) => {
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    let avatar;
    try {
      const loggedInUserAvatar = getLocalStorageUserData("Avatar");
      if (!loggedInUserAvatar) {
        const response = await api.usersAvatarGet();
        avatar = response.data ? `data:image/png;base64,${String(response.data)}` : "";
        setLocalStorageUserData("Avatar", avatar);
      } else {
        avatar = loggedInUserAvatar;
      }
    } catch {
      avatar = "";
    }
    return avatar;
  }
);

export const updateUserAvatar = createAsyncThunk(
  "users/updateUserAvatar",
  async (args: { avatar: { file: File; base64File: string }; cb?: (() => void)[] }, thunkAPI) => {
    try {
      const {
        avatar: { file },
        cb,
      } = args;
      const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
      const { UUID } = (thunkAPI.getState() as RootState).config.userConfig;
      const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

      await api.usersAvatarUserUUIDPost(UUID, file);
      cb?.forEach((fn) => fn());
      showSuccessToast(userAvatarUpdateSuccess);
    } catch (err) {
      showErrorToast(userAvatarUploadFailure);
    }
  }
);

export const inviteUsers = createAsyncThunk(
  "users/inviteUsers",
  async (
    args: {
      payload: {
        users: {
          email: string;
          roles: string[];
        }[];
      };
      cb: (() => void)[];
    },
    thunkAPI
  ) => {
    const { payload, cb } = args;
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;

    const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      const response = await api.usersInvitePost(payload);
      const { data } = response;
      cb.forEach((fn) => fn());
      return data;
    } catch (err) {
      showErrorToast(inviteUserFailure);
      return {
        failedToInviteUsers: [],
        invitedUsers: [],
      };
    }
  }
);

export const revokeUser = createAsyncThunk(
  "users/revokeUser",
  async (args: { payload: { userID: number } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      const { payload } = args;
      const { userID } = payload;
      const response = (await api.usersRevokeUserIDPatch(userID)).data;
      showSuccessToast("User access revoked successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const acceptInvite = createAsyncThunk(
  "users/acceptInvite",
  async (args: { payload: { email: string; token: string } }, { rejectWithValue }) => {
    const api = new UsersApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      const { payload } = args;
      const response = (await api.usersAcceptInvitationPost(payload)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteUserAvatar = createAsyncThunk("users/deleteAvatar", async (_, thunkAPI) => {
  const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
  const { UUID } = (thunkAPI.getState() as RootState).config.userConfig;
  const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
  try {
    await api.usersAvatarUserUUIDDelete(UUID);
    showSuccessToast(userAvatarDeleteSuccess);
  } catch {
    showErrorToast(userAvatarDeleteFailure);
  }
});

// TODO(MCT-489): This needs to be updated in both openAPI and accordingly here to update user
// export const updateUser = createAsyncThunk(
//   "users/updateUser",
//   async (args: { user: UserConfig; cb: () => void }, thunkAPI) => {
//     const { user, cb } = args;
//     const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
//     const { UUID, CognitoID, TenantUUID } = (thunkAPI.getState() as RootState).config.userConfig;
//     const api = new UsersApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

//     try {
//       await api.usersUserUUIDPut(UUID, {
//         ...user,
//         UUID,
//         CognitoID,
//         TenantUUID,
//       });
//       showSuccessToast(userUpdateSuccess);
//       cb();
//     } catch (err) {
//       showErrorToast(userUpdateFailure);
//     }
//   }
// );

export const changeUserPassword = createAsyncThunk(
  "users/changePassword",
  async (
    args: { passwords: Omit<ChangePasswordConfig, "AuthToken">; cb: () => void },
    thunkAPI
  ) => {
    const { passwords, cb } = args;
    const { userToken } = (thunkAPI.getState() as RootState).application.currentUser;
    const accessTokenKey = Object.keys(localStorage).find((key) => key.includes("accessToken"));
    const accessToken = accessTokenKey ? localStorage.getItem(accessTokenKey) : "";
    const api = new UsersApi(
      { accessToken: userToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      if (accessToken) {
        await api.usersChangePasswordPost({ ...passwords });
        showSuccessToast(userPasswordChangeSuccess);
        cb();
      } else {
        showErrorToast(userAuthenticationFailure);
      }
    } catch {
      showErrorToast(userPasswordChangeFailure);
    }
  }
);

export const getUserBalance = createAsyncThunk(
  "users/getUserBalance",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PaymentApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.paymentUserBalanceGet();
      const { data } = response;
      return data;
    } catch (err) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUserBillingInformation = createAsyncThunk(
  "users/getUserBillingInformation",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PaymentApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.paymentUserBillingDetailsGet();
      const { data } = response;
      return data;
    } catch (err) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateUserBillingInformation = createAsyncThunk(
  "users/updateUserBillingInformation",
  async (
    args: {
      payload: {
        tax_type: string;
        name: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
        city: string;
        country: string;
        tax_id: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = args;

      const accessToken = Cookies.get("token");
      const api = new PaymentApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.paymentUserBillingDetailsPut(payload);
      showSuccessToast(userUpdateBillingChangeSuccess);
      return "Update User Billing Information.";
    } catch (err) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUserPaymentPortalUrl = createAsyncThunk(
  "users/getUserPaymentPortalUrl",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PaymentApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.paymentMethodPortalGet();
      const { data } = response;

      return data;
    } catch (err) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: { users: [] as UserConfigPublic[], total_count: 0 } as UsersGet200Response,
    usersFilter: [] as Users,
    usersIsLoading: false,
    getUserPaymentPortalUrlIsLoading: false,
    updateUserBillingInformationLoading: false,
    invitedUsersMessages: {} as UsersInvitePost200Response,
    currentUserAvatar: "" as string,
    balance: {} as UserBalance,
    billingDetails: {} as BillingDetailsResponse,
  },
  reducers: {
    clearInvitesMessages: (state) => {
      state.invitedUsersMessages = {
        invitedUsers: [],
        failedToInviteUsers: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.usersIsLoading = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.usersIsLoading = true;
      })
      .addCase(getUsers.rejected, (state) => {
        state.users = {};
        state.usersIsLoading = false;
      })
      .addCase(getUsersFilter.fulfilled, (state, action) => {
        state.usersFilter = action.payload;
        state.usersIsLoading = false;
      })
      .addCase(getUsersFilter.pending, (state) => {
        state.usersIsLoading = true;
      })
      .addCase(getUsersFilter.rejected, (state) => {
        state.users = {};
        state.usersIsLoading = false;
      })
      .addCase(revokeUser.fulfilled, (state) => {
        state.usersIsLoading = false;
      })
      .addCase(revokeUser.pending, (state) => {
        state.usersIsLoading = true;
      })
      .addCase(revokeUser.rejected, (state) => {
        state.usersIsLoading = false;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
        state.usersIsLoading = false;
      })
      .addCase(getUserBalance.pending, (state) => {
        state.usersIsLoading = true;
      })
      .addCase(getUserBillingInformation.fulfilled, (state, action) => {
        state.billingDetails = action.payload;
        state.usersIsLoading = false;
      })
      .addCase(getUserBillingInformation.pending, (state) => {
        state.usersIsLoading = true;
      })

      .addCase(updateUserBillingInformation.fulfilled, (state) => {
        state.updateUserBillingInformationLoading = false;
      })
      .addCase(updateUserBillingInformation.pending, (state) => {
        state.updateUserBillingInformationLoading = true;
      })

      .addCase(getUserPaymentPortalUrl.fulfilled, (state) => {
        state.getUserPaymentPortalUrlIsLoading = false;
      })
      .addCase(getUserPaymentPortalUrl.pending, (state) => {
        state.getUserPaymentPortalUrlIsLoading = true;
      })
      .addCase(inviteUsers.fulfilled, (state, action) => {
        state.invitedUsersMessages = action.payload;
      })
      .addCase(getLoggedInUserAvatar.fulfilled, (state, action) => {
        state.currentUserAvatar = action.payload;
      })
      .addCase(updateUserAvatar.pending, (state, action) => {
        state.currentUserAvatar = action.meta.arg.avatar.base64File;
      })
      .addCase(updateUserAvatar.fulfilled, (_, action) => {
        setLocalStorageUserData("Avatar", action.meta.arg.avatar.base64File);
      })
      .addCase(updateUserAvatar.rejected, (state) => {
        state.currentUserAvatar = getLocalStorageUserData("Avatar") || "";
      })
      .addCase(deleteUserAvatar.fulfilled, (state) => {
        state.currentUserAvatar = "";
        setLocalStorageUserData("Avatar", "");
      })
      .addCase("application/logout", (state) => {
        state.users = {};
        state.invitedUsersMessages = {} as UsersInvitePost200Response;
        state.billingDetails = {};
        state.currentUserAvatar = "";
        state.usersFilter = [] as Users;
      });
  },
});
export default usersSlice.reducer;

export const { clearInvitesMessages } = usersSlice.actions;
export const selectAllUsers = (state: RootState) => state.users.users;
export const selectUsersLoadingState = (state: RootState) => state.users.usersIsLoading;

export const selectUserName = (state: RootState, userId: number | undefined) => {
  const selectedUser = state.users.users.users?.find((user) => user.id === userId);
  return selectedUser ? selectedUser.name : "";
};
export const selectUser = (state: RootState, userId: number | undefined) => {
  const selectedUser = state.users.users.users?.find((user) => user.id === userId);
  return selectedUser;
};

export const selectAvatar = (state: RootState, userId: number | undefined) =>
  state.users.users.users?.find((user) => user.id === userId)?.avatar;

export const selectUsersFilter = (state: RootState) => {
  const users = state.users.usersFilter;

  return {
    users,
    usersIsLoading: state.users.usersIsLoading,
  };
};
