import { toast, ToastOptions, UpdateOptions } from "react-toastify";

const toastOptions: ToastOptions<Record<string, unknown>> = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const showErrorToast = (message: string) => toast.error(message, toastOptions);
export const showLoadingToast = (message: string) => toast.loading(message, toastOptions);
export const showSuccessToast = (message: string) => toast.success(message, toastOptions);
export const updateToast = (id: string | number, options: UpdateOptions) =>
  toast.update(id, { ...toastOptions, ...options });
