import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Policy, Document } from "utils/data/interfaces";
import {
  Configuration,
  PolicyApi,
  HelperDocsApi,
  FileApi,
  DocumentType,
  FailedResponse,
} from "mycio-openapi";
import type { RootState } from "store";
import { format, parseISO } from "date-fns";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "utils/toasts";

interface PaginationPayload {
  page: number;
  limit: number;
}
interface PolicyWithFileType extends Policy {
  fileType?: string | null;
}
interface DocumentWithFileType extends Document {
  fileType?: string | null;
}
export const getDocuments = createAsyncThunk<
  { total: number; documents: Document[] },
  PaginationPayload
>("documents/getDocuments", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new HelperDocsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { data } = await api.helperdocsGet(arg.page, arg.limit);

    const documents = data.document_list.map((doc) => {
      if (!doc.id) {
        throw new Error("missing document ID");
      }
      const mappedDocument: Document = {
        id: doc.id,
        title: doc.name,
        date: format(parseISO(doc.istamp ?? ""), "yyyy-MM-dd"),
      };
      return mappedDocument;
    });

    return { total: data.total ?? 0, documents };
  } catch {
    return rejectWithValue("Something went wrong");
  }
});
export const getPolicies = createAsyncThunk<
  { total: number; policies: Policy[] | undefined },
  PaginationPayload
>("policies/getPolicies", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new PolicyApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

    const { data } = await api.policyGet(arg.page, arg.limit);

    const policies: Policy[] | undefined = data.document_list?.map((policy) => {
      if (!policy.id) {
        throw new Error("missing policy ID");
      }
      const mappedPolicy: Policy = {
        id: policy.id,
        title: policy.filename,
        status: policy.status,
        version: policy.version,
        owner: policy.owner?.name,
        date: format(parseISO(policy.istamp ?? ""), "yyyy-MM-dd"),
      };
      return mappedPolicy;
    });

    return { total: data.total ?? 0, policies };
  } catch {
    return rejectWithValue("Something went wrong");
  }
});

export const getDocumentById = createAsyncThunk<DocumentWithFileType, string>(
  "documents/getDocumentById",
  async (uuid, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new HelperDocsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const { data } = await api.helperdocsDocIDGet(+uuid);
      const { data: signedURL } = await api.helperdocsDocIDGet(+uuid);

      const mappedDocument: DocumentWithFileType = {
        id: data.id,
        title: data.name,
        date: format(parseISO(data.istamp ?? ""), "yyyy-MM-dd"),
        fileUrl: signedURL.url,
        description: data.description,
        fileType: signedURL.file_extension,
      };
      return mappedDocument;
    } catch {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAcknowledgementPolicy = createAsyncThunk(
  "policies/getAcknowledgementPolicyDocument",
  async (args: { id: number; token: string }, { rejectWithValue, getState }) => {
    const { id, token } = args;
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.policyPolicyFileIDTokenPolicyFileTokenGet(id, token);
      const { data } = response;

      const mappedPolicy: PolicyWithFileType = {
        id: data.id,
        title: data.filename,
        status: data.status,
        owner: data.owner?.name,
        date: format(parseISO(data.istamp ?? ""), "yyyy-MM-dd"),
        version: data.version,
        fileUrl: data.url,
        description: data.description,
        fileType: data.file_extension,
      };
      return mappedPolicy;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const postAcknowledgementPolicy = createAsyncThunk(
  "documents/postAcknowledgementPolicy",
  async (args: { id: number; token: string }, thunkAPI) => {
    const { id, token } = args;
    const { rejectWithValue } = thunkAPI;
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new PolicyApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      await api.policyPolicyFileIDAcknowledgePost(id, { token });
      showSuccessToast("Policy acknowledged successfully");
      return true;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPolicyById = createAsyncThunk<PolicyWithFileType, string>(
  "policies/getPolicyById",
  async (uuid, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const { data } = await api.policyPolicyFileIDGet(+uuid);
      const { data: signedURL } = await api.policyFilePolicyFileIDGet(+uuid);

      const mappedPolicy: PolicyWithFileType = {
        id: data.id,
        title: data.filename,
        status: data.status,
        owner: data.owner?.name,
        date: format(parseISO(data.istamp ?? ""), "yyyy-MM-dd"),
        ownerId: data.owner?.id,
        version: data.version,
        fileUrl: signedURL.url,
        description: data.description,
        fileType: signedURL.file_extension,
      };

      return mappedPolicy;
    } catch {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "documents/uploadDocument",
  async (
    args: {
      file: File;
      fileName: string;
      fileType: DocumentType;
      fileSubtype: string;
      fileDescription: string;
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new FileApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

    try {
      await api.filePost(
        args.file,
        args.fileName,
        args.fileType,
        args.fileSubtype,
        args.fileDescription
      );
      showSuccessToast("Document added successfully");
      return true;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSubtypes = createAsyncThunk<string[], DocumentType>(
  "documents/getSubtypes",
  async (typeId: DocumentType, { getState, rejectWithValue }) => {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new FileApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      const { data } = await api.fileTypesTypeGet(typeId);
      return data;
    } catch (e) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const publishPolicy = createAsyncThunk<void, number>(
  "policies/publishPolicy",
  async (policyFileID, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.policyPolicyFileIDPublishPatch(+policyFileID)).data;
      showSuccessToast("Policy published successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updatePolicyDetails = createAsyncThunk(
  "policies/updatePolicyDetails",
  async (
    args: { policyFileID: number; fileName: string; fileDescription: string; ownerId: number },
    { rejectWithValue, getState }
  ) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.policyPolicyFileIDPut(
          args.policyFileID,
          args.fileName,
          args.fileDescription,
          args.ownerId
        )
      ).data;
      showSuccessToast("Policy details updated successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const replacePolicyFile = createAsyncThunk(
  "policies/replacePolicyFile",
  async (args: { policyFileID: number; file: File }, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.policyPolicyFileIDFilePut(args.policyFileID, args.file)).data;
      showSuccessToast("Policy file replaced successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deletePolicy = createAsyncThunk<void, number>(
  "policies/deletePolicy",
  async (policyFileID, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new PolicyApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.policyPolicyFileIDDelete(+policyFileID)).data;
      showSuccessToast("Policy deleted successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    documents: { total: 0, documents: [] as Document[] },
    policies: { total: 0, policies: [] as Policy[] | undefined },
    policyById: {} as Policy,
    acknowledgementPolicy: {} as PolicyWithFileType,
    documentById: {} as Document,
    documentsLoading: false,
    policiesLoading: false,
    documentByIdLoading: false,
    policyByIdLoading: false,
    publishLoading: false,
    deleteLoading: false,
    updateLoading: false,
    acknowledgementPolicyLoading: true,
    subtypes: [] as string[],
    subtypesLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocuments.pending, (state) => {
        state.documentsLoading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.documentsLoading = false;
      })
      .addCase(getDocuments.rejected, (state) => {
        state.documentsLoading = false;
      })
      .addCase(getPolicies.pending, (state) => {
        state.policiesLoading = true;
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.policies = action.payload;
        state.policiesLoading = false;
      })
      .addCase(getPolicies.rejected, (state) => {
        state.policiesLoading = false;
      })
      .addCase(getDocumentById.pending, (state) => {
        state.documentByIdLoading = true;
      })
      .addCase(getDocumentById.fulfilled, (state, action) => {
        state.documentById = action.payload;
        state.documentByIdLoading = false;
      })
      .addCase(getDocumentById.rejected, (state) => {
        state.documentByIdLoading = false;
      })
      .addCase(getPolicyById.pending, (state) => {
        state.policyByIdLoading = true;
      })
      .addCase(getPolicyById.fulfilled, (state, action) => {
        state.policyById = action.payload;
        state.policyByIdLoading = false;
      })
      .addCase(getPolicyById.rejected, (state) => {
        state.policyByIdLoading = false;
      })
      .addCase(getAcknowledgementPolicy.pending, (state) => {
        state.acknowledgementPolicyLoading = true;
      })
      .addCase(getAcknowledgementPolicy.fulfilled, (state, action) => {
        state.acknowledgementPolicy = action.payload;
        state.acknowledgementPolicyLoading = false;
      })
      .addCase(getAcknowledgementPolicy.rejected, (state) => {
        state.acknowledgementPolicyLoading = false;
      })
      .addCase(getSubtypes.pending, (state) => {
        state.subtypesLoading = true;
      })
      .addCase(getSubtypes.fulfilled, (state, action) => {
        state.subtypes = action.payload;
        state.subtypesLoading = false;
      })
      .addCase(getSubtypes.rejected, (state) => {
        state.subtypesLoading = false;
      })
      .addCase(publishPolicy.pending, (state) => {
        state.publishLoading = true;
      })
      .addCase(publishPolicy.fulfilled, (state) => {
        state.publishLoading = false;
      })
      .addCase(publishPolicy.rejected, (state) => {
        state.publishLoading = false;
      })
      .addCase(updatePolicyDetails.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updatePolicyDetails.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updatePolicyDetails.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(replacePolicyFile.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(replacePolicyFile.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(replacePolicyFile.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(deletePolicy.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deletePolicy.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deletePolicy.rejected, (state) => {
        state.deleteLoading = false;
      })
      .addCase("application/logout", (state) => {
        state.documents = { total: 0, documents: [] as Document[] };
        state.policies = { total: 0, policies: [] as Policy[] | undefined };
        state.policyById = {};
        state.acknowledgementPolicy = {};
        state.documentById = {};
        state.subtypes = [];
      });
  },
});

export default documentsSlice.reducer;
