import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  changeUserPassword,
  getLoggedInUserAvatar,
  inviteUsers,
  // TODO(MCT-489): This needs to be updated in both openAPI and accordingly here to update user
  // updateUser,
  updateUserAvatar,
} from "store/users";

enum IsloadingState {
  getUserAvatarisLoading = "getUserAvatarIsLoading",
  updateUserAvatarIsLoading = "updateUserAvatarIsLoading",
  updateUserIsLoading = "updateUserIsLoading",
  changeUserPasswordIsLoading = "changeUserPasswordIsLoading",
  isInvitingUsers = "isInvitingUsers",
  cloudResourcesIsLoading = "clouResourcesIsLoading",
}

interface IloadingState {
  [IsloadingState.getUserAvatarisLoading]: boolean;
  [IsloadingState.updateUserAvatarIsLoading]: boolean;
  [IsloadingState.updateUserIsLoading]: boolean;
  [IsloadingState.changeUserPasswordIsLoading]: boolean;
  [IsloadingState.isInvitingUsers]: boolean;
  [IsloadingState.cloudResourcesIsLoading]: boolean;
}

const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    [IsloadingState.getUserAvatarisLoading]: false,
    [IsloadingState.updateUserAvatarIsLoading]: false,
    [IsloadingState.updateUserIsLoading]: false,
    [IsloadingState.changeUserPasswordIsLoading]: false,
    [IsloadingState.isInvitingUsers]: false,
    [IsloadingState.cloudResourcesIsLoading]: false,
  } as IloadingState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // TODO(MCT-489): This needs to be updated in both openAPI and accordingly here to update user
      // .addCase(updateUser.pending, (state) => {
      //   state[IsloadingState.updateUserAvatarIsLoading] = true;
      // })
      // .addCase(updateUser.fulfilled, (state) => {
      //   state[IsloadingState.updateUserAvatarIsLoading] = false;
      // })
      // .addCase(updateUser.rejected, (state) => {
      //   state[IsloadingState.updateUserAvatarIsLoading] = false;
      // })
      .addCase(changeUserPassword.pending, (state) => {
        state[IsloadingState.changeUserPasswordIsLoading] = true;
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state[IsloadingState.changeUserPasswordIsLoading] = false;
      })
      .addCase(changeUserPassword.rejected, (state) => {
        state[IsloadingState.changeUserPasswordIsLoading] = false;
      })
      .addCase(updateUserAvatar.pending, (state) => {
        state[IsloadingState.updateUserAvatarIsLoading] = true;
      })
      .addCase(updateUserAvatar.fulfilled, (state) => {
        state[IsloadingState.updateUserAvatarIsLoading] = false;
      })
      .addCase(updateUserAvatar.rejected, (state) => {
        state[IsloadingState.updateUserAvatarIsLoading] = false;
      })
      .addCase(getLoggedInUserAvatar.pending, (state) => {
        state[IsloadingState.getUserAvatarisLoading] = true;
      })
      .addCase(getLoggedInUserAvatar.fulfilled, (state) => {
        state[IsloadingState.getUserAvatarisLoading] = false;
      })
      .addCase(getLoggedInUserAvatar.rejected, (state) => {
        state[IsloadingState.getUserAvatarisLoading] = false;
      })
      .addCase(inviteUsers.pending, (state) => {
        state[IsloadingState.isInvitingUsers] = true;
      })
      .addCase(inviteUsers.fulfilled, (state) => {
        state[IsloadingState.isInvitingUsers] = false;
      })
      .addCase(inviteUsers.rejected, (state) => {
        state[IsloadingState.isInvitingUsers] = false;
      });
  },
});

export const selectLoading = (state: RootState) => state.loading;

export default loadingSlice.reducer;
