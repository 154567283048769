import { IconProps } from "components/icons/Icon.interface";

export default function Intune({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 217.34 198.02"
      className={className}
    >
      <defs>
        <linearGradient
          id="a"
          x1="372.08"
          y1="356.33"
          x2="372.08"
          y2="485.02"
          gradientTransform="matrix(1, 0, 0, -1, -275.5, 485.02)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0078d4" />
          <stop offset="0.82" stopColor="#5ea0ef" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="371.56"
          y1="306.57"
          x2="371.56"
          y2="356.33"
          gradientTransform="matrix(1, 0, 0, -1, 0, 770)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1490df" />
          <stop offset="0.98" stopColor="#1f56a3" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="372.08"
          y1="400.46"
          x2="372.08"
          y2="437.95"
          gradientTransform="matrix(1, 0, 0, -1, -275.5, 485.02)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d2ebff" />
          <stop offset="1" stopColor="#f0fffd" />
        </linearGradient>
      </defs>
      <title>Microsoft Intune logo - Brandlogos.net</title>
      <rect width="193.16" height="128.69" rx="6.4" ry="6.4" style={{ fill: "url(#a)" }} />
      <rect
        x="10.75"
        y="10.75"
        width="171.67"
        height="107.32"
        rx="3.58"
        ry="3.58"
        style={{ fill: "url(#b)" }}
      />
      <path
        d="M410.84,452.68c-19.19-2.94-20-16.76-19.83-38.38H352.63c0,22.26-.77,36.07-19.83,38.38a11.13,11.13,0,0,0-9.47,10.75h96.45A11.26,11.26,0,0,0,410.84,452.68Z"
        transform="translate(-275.5 -284.98)"
        style={{ fill: "url(#c)" }}
      />
      <path
        d="M488.74,344.46h-88a29.55,29.55,0,1,0,0,11.77h9.08V478.78A4.22,4.22,0,0,0,414,483h74.58a4.22,4.22,0,0,0,4.22-4.22V348.68A4.22,4.22,0,0,0,488.74,344.46Z"
        transform="translate(-275.5 -284.98)"
        style={{ fill: "#32bedd" }}
      />
      <rect
        x="142.25"
        y="71.12"
        width="67.41"
        height="111.29"
        rx="1.54"
        ry="1.54"
        style={{ fill: "#fff" }}
      />
      <circle
        cx="96.58"
        cy="65.88"
        r="18.68"
        style={{
          opacity: 0.9,
          isolation: "isolate",
          fill: "url(#c)",
        }}
      />
      <path
        d="M459.45,407.27l-14.33-14.33a.77.77,0,0,0-1.28.64v8.7a.77.77,0,0,1-.77.77H409.81v10.62h33.26a.77.77,0,0,1,.77.77h0v8.83a.77.77,0,0,0,1.28,0l14.33-13.69a1.54,1.54,0,0,0,.14-2.17Z"
        transform="translate(-275.5 -284.98)"
        style={{
          fill: "#0078d4",
        }}
      />
    </svg>
  );
}
