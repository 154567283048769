import { intl } from "utils/intl";

export const taskCreateSuccessMessage = (task: string) =>
  intl.formatMessage(
    {
      id: "toast-task-create-success",
      defaultMessage: "{task} Created Successfully",
    },
    { task }
  );

export const taskCreateFailureMessage = intl.formatMessage({
  id: "toast-task-create-failure",
  defaultMessage: "Task Couldn't be Created",
});

export const taskUpdateSuccessMessage = (task: string) =>
  intl.formatMessage(
    {
      id: "toast-task-update-success",
      defaultMessage: "{task} Updated Successfully",
    },
    { task }
  );

export const taskUpdateFailureMessage = intl.formatMessage({
  id: "toast-task-update-failure",
  defaultMessage: "Task Couldn't be Updated",
});

export const evidenceUploadLoadingMessage = intl.formatMessage({
  id: "toast-evidence-upload-loading",
  defaultMessage: "Uploading Evidence...",
});

export const evidenceUploadSuccessMessage = intl.formatMessage({
  id: "toast-evidence-upload-success",
  defaultMessage: "Upload Complete!",
});

export const evidenceUploadFailureMessage = intl.formatMessage({
  id: "toast-evidence-upload-failure",
  defaultMessage: "Upload Failed!",
});

export const evidenceDownloadLoadingMessage = intl.formatMessage({
  id: "toast-evidence-download-loading",
  defaultMessage: "Downloading Evidence...",
});

export const evidenceDownloadSuccessMessage = intl.formatMessage({
  id: "toast-evidence-download-success",
  defaultMessage: "Download Complete!",
});

export const evidenceDownloadFailureMessage = intl.formatMessage({
  id: "toast-evidence-download-failure",
  defaultMessage: "Download Failed!",
});

export const userUpdateSuccess = intl.formatMessage({
  id: "toast-user-update-success",
  defaultMessage: "User Data Updated Successfully",
});

export const userUpdateFailure = intl.formatMessage({
  id: "toast-user-update-failure",
  defaultMessage: "Couldn't Update User Data",
});

export const userAvatarUploadFailure = intl.formatMessage({
  id: "toast-user-avatar-upload-failure",
  defaultMessage: "Avatar upload Failed!",
});

export const userAvatarUpdateSuccess = intl.formatMessage({
  id: "toast-user-avatar-update-success",
  defaultMessage: "Avatar Updated Successfully",
});

export const userAvatarDeleteSuccess = intl.formatMessage({
  id: "toast-user-avatar-delete-success",
  defaultMessage: "Avatar Deleted Successfully",
});

export const userAvatarDeleteFailure = intl.formatMessage({
  id: "toast-user-avatar-delete-failure",
  defaultMessage: "Avatar Couldn't be Deleted!",
});

export const userPasswordChangeSuccess = intl.formatMessage({
  id: "toast-user-password-change-success",
  defaultMessage: "Password Changed Successfully",
});

export const userPasswordChangeFailure = intl.formatMessage({
  id: "toast-user-password-change-failure",
  defaultMessage: "Password couldn't be Changed!",
});

export const userAuthenticationFailure = intl.formatMessage({
  id: "toast-user-authentication-failure",
  defaultMessage: "Couldn't authenticate user - please try logging out and logging in",
});

export const recommendedAgentsFetchFailureMessage = intl.formatMessage({
  id: "toast-recommended-agents-fetch-failure",
  defaultMessage: "Couldn't fetch recommended agents",
});

export const inviteUserSuccess = intl.formatMessage({
  id: "toast-invite-user-success",
  defaultMessage: "Invite Sent Successfully",
});

export const inviteUserFailure = intl.formatMessage({
  id: "toast-invite-user-failure",
  defaultMessage: `Error Sending Invite`,
});

export const controlsStatusFetchFailureMessage = intl.formatMessage({
  id: "toast-controls-status-fetch-failure",
  defaultMessage: "Couldn't fetch controls status",
});

export const devicesFetchFailureMessage = intl.formatMessage({
  id: "toast-devices-fetch-failure",
  defaultMessage: "Couldn't fetch devices",
});

export const employeesFetchFailureMessage = intl.formatMessage({
  id: "toast-employees-fetch-failure",
  defaultMessage: "Couldn't fetch employees",
});

export const awsFetchFailureMessage = intl.formatMessage({
  id: "toast-aws-fetch-failure",
  defaultMessage: "Couldn't fetch AWS resources",
});

export const userUpdateBillingChangeSuccess = intl.formatMessage({
  id: "toast-edit-payment-information-success",
  defaultMessage: "Payment Information Changed Successfully",
});
