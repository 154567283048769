export const getRumFunction = () => {
  let rumFunction = "";
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "sandbox") {
    rumFunction = `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})("cwr", "c4e4b66d-6f05-4fa3-ab15-c1877f99498d", "1.0.0", "us-east-2", "https://client.rum.us-east-1.amazonaws.com/1.5.x/cwr.js", { sessionSampleRate: 1, guestRoleArn: "arn:aws:iam::635139080794:role/app.dev.mycio.io-RUM-UnauthenticatedRole", identityPoolId: "us-east-2:a7d8818f-e020-4092-9190-7cb903eee9aa", endpoint: "https://dataplane.rum.us-east-2.amazonaws.com", telemetries: ["errors", "performance", "http"], allowCookies: true, enableXRay: true});`;
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === "dev") {
    rumFunction = `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})("cwr", "c4e4b66d-6f05-4fa3-ab15-c1877f99498d", "1.0.0", "us-east-2", "https://client.rum.us-east-1.amazonaws.com/1.5.x/cwr.js", { sessionSampleRate: 1, guestRoleArn: "arn:aws:iam::635139080794:role/app.dev.mycio.io-RUM-UnauthenticatedRole", identityPoolId: "us-east-2:a7d8818f-e020-4092-9190-7cb903eee9aa", endpoint: "https://dataplane.rum.us-east-2.amazonaws.com", telemetries: ["errors", "performance", "http"], allowCookies: true, enableXRay: true});`;
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT === "prod") {
    rumFunction = `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})('cwr','fbb1a186-6f2c-4ad4-bbe4-156e741d2d05','1.0.0','ap-southeast-1','https://client.rum.us-east-1.amazonaws.com/1.5.x/cwr.js',{sessionSampleRate: 1,guestRoleArn: "arn:aws:iam::717175735559:role/app.mycio.io-RUM-UnauthenticatedRole",identityPoolId: "ap-southeast-1:4d496594-ec14-4200-88d3-b05c29be1537",endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",telemetries: ["errors","performance","http"],allowCookies: true,enableXRay: true});`;
  }
  return rumFunction;
};
