import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import type { RootState } from "store";
import axios from "axios";
import {
  Configuration,
  ContractualRequirementsApi,
  ContractualRequirementsGet200Response,
  ContractualRequirementsPostRequest,
  FailedResponse,
  LawIntegrationApi,
  LawIntegrationRequest,
  OrganizationalUnit,
  TenantOrganizationalRolesPutRequest,
  InterestedPartiesApi,
  InterestedPartiesGet200Response,
  InterestedPartiesPostRequest,
  LawIntegrationGet200Response,
  OrganizationsUnitsGet200Response,
  DefaultApi,
  OrganizationsUnitsPostRequest,
  OrganizationsUnitsIdPatchRequest,
  TenantApi,
  OrganizationsLocationsGet200Response,
  OrganizationsLocationsPostRequest,
  OrganizationsCountriesGet200Response,
  OrganizationsUnitsPost200Response,
} from "mycio-openapi";
import { response } from "msw";

interface PaginationPayload {
  page?: number;
  limit?: number;
}

export const getOrganizationRoles = createAsyncThunk<OrganizationalUnit[]>(
  "organization/getOrganizationRoles",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TenantApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.tenantOrganizationalRolesGet()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const changeOrganizationRoles = createAsyncThunk<void, TenantOrganizationalRolesPutRequest>(
  "organization/changeOrganizationRoles",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TenantApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.tenantOrganizationalRolesPut({ roles: arg.roles });
      showSuccessToast("Roles changed successfully");
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getLawsAndRegulation = createAsyncThunk<
  LawIntegrationGet200Response,
  PaginationPayload
>("organization/getLawsAndRegulation", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new LawIntegrationApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.lawIntegrationGet(arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const addLawsAndRegulation = createAsyncThunk<void, LawIntegrationRequest>(
  "organization/addLawsAndRegulation",
  async (arg: LawIntegrationRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new LawIntegrationApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.lawIntegrationPost(arg);
      showSuccessToast("Law added successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const deleteLawsAndRegulation = createAsyncThunk<void, number>(
  "organization/deleteLawsAndRegulation",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new LawIntegrationApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.lawIntegrationIdDelete(arg);
      showSuccessToast("Laws deleted successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const getContractualRequirements = createAsyncThunk<
  ContractualRequirementsGet200Response,
  PaginationPayload
>("organization/getContractualRequirements", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new ContractualRequirementsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.contractualRequirementsGet(arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const addContractualRequirement = createAsyncThunk<void, ContractualRequirementsPostRequest>(
  "organization/addContractualRequirement",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ContractualRequirementsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.contractualRequirementsPost(arg);
      showSuccessToast("Law added successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const deleteContractualRequirement = createAsyncThunk<void, number>(
  "organization/deleteContractualRequirement",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ContractualRequirementsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.contractualRequirementsIdDelete(arg);
      showSuccessToast("Laws deleted successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const getInterestedParties = createAsyncThunk<
  InterestedPartiesGet200Response,
  PaginationPayload
>("organization/getInterestedParties", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new InterestedPartiesApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.interestedPartiesGet(arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const addInterestedParties = createAsyncThunk<void, InterestedPartiesPostRequest>(
  "organization/addInterestedParties",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new InterestedPartiesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.interestedPartiesPost(arg);
      showSuccessToast("Interested Parti added successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const deleteInterestedParties = createAsyncThunk<void, number>(
  "organization/deleteInterestedParties",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new InterestedPartiesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.interestedPartiesIdDelete(arg);
      showSuccessToast("Interested Parties deleted successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);

export const getOrganizationUnits = createAsyncThunk<
  OrganizationsUnitsGet200Response,
  PaginationPayload,
  { rejectValue: string }
>("organization/getOrganizationUnits", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = await api.organizationsUnitsGet(arg.page, arg.limit);
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue(errorMessage);
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const addOrganizationUnit = createAsyncThunk<
  OrganizationsUnitsPost200Response,
  OrganizationsUnitsPostRequest
>("organization/addOrganizationUnit", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.organizationsUnitsPost(arg)).data;
    showSuccessToast("Organizational Unit added successfully");
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue(errorMessage);
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const editOrganizationUnit = createAsyncThunk<
  void,
  { id: number; organizationsUnitsIdPatchRequest: OrganizationsUnitsIdPatchRequest }
>("organization/editOrganizationUnit", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    await api.organizationsUnitsIdPatch(arg.id, arg.organizationsUnitsIdPatchRequest);
    showSuccessToast("Organizational Unit changed successfully");
    return;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        rejectWithValue(errorMessage);
      }
    }
    showErrorToast("Something went wrong.");
    rejectWithValue("Something went wrong");
  }
});
export const deleteOrganizationUnit = createAsyncThunk<void, number, { rejectValue: string }>(
  "organization/deleteOrganizationUnit",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DefaultApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.organizationsUnitsIdDelete(arg);
      showSuccessToast("Organizational Unit deleted successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue(errorMessage);
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);

export const getOrganizationLocations = createAsyncThunk<
  OrganizationsLocationsGet200Response,
  PaginationPayload
>("organization/getOrganizationLocations", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.organizationsLocationsGet(arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});
export const addOrganizationLocation = createAsyncThunk<void, OrganizationsLocationsPostRequest>(
  "organization/addOrganizationLocation",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DefaultApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.organizationsLocationsPost(arg);
      showSuccessToast("Organization Location added successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const editOrganizationLocation = createAsyncThunk<
  void,
  { id: number; organizationsLocationsPostRequest: OrganizationsLocationsPostRequest }
>("organization/editOrganizationLocation", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    await api.organizationsLocationsIdPatch(arg.id, arg.organizationsLocationsPostRequest);
    showSuccessToast("Organization Location changed successfully");
    return;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    rejectWithValue("Something went wrong");
  }
});
export const deleteOrganizationLocation = createAsyncThunk<void, number>(
  "organization/deleteOrganizationLocation",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DefaultApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.organizationsLocationsIdDelete(arg);
      showSuccessToast("Organization Location deleted successfully");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);
export const getOrganizationsCountries = createAsyncThunk<
  OrganizationsCountriesGet200Response,
  void,
  { rejectValue: { message: string } }
>("organization/getOrganizationsCountries", async (_, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DefaultApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.organizationsCountriesGet()).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue({ message: "Something went wrong" });
  }
});

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organizationRoles: [] as OrganizationalUnit[],
    organizationIsLoading: false,
    changeOrganizationRolesIsLoading: false,
    lawsAndRegulation: {},
    lawsAndRegulationIsLoading: false,
    contractualRequirements: {},
    contractualRequirementsIsLoading: false,
    interestedParties: {},
    interestedPartiesIsLoading: false,
    organizationalUnit: {},
    organizationalUnitIsLoading: false,
    organizationLocation: {} as OrganizationsLocationsGet200Response,
    organizationLocationIsLoading: false,
    organizationCountries: {} as OrganizationsCountriesGet200Response,
    organizationCountriesIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationRoles.pending, (state) => {
        state.organizationIsLoading = true;
      })
      .addCase(getOrganizationRoles.fulfilled, (state, action) => {
        state.organizationRoles = action.payload;
        state.organizationIsLoading = false;
      })
      .addCase(getOrganizationRoles.rejected, (state) => {
        state.organizationIsLoading = false;
      })
      .addCase(changeOrganizationRoles.pending, (state) => {
        state.changeOrganizationRolesIsLoading = true;
      })
      .addCase(changeOrganizationRoles.fulfilled, (state) => {
        state.changeOrganizationRolesIsLoading = false;
      })
      .addCase(changeOrganizationRoles.rejected, (state) => {
        state.changeOrganizationRolesIsLoading = false;
      })
      .addCase(getLawsAndRegulation.pending, (state) => {
        state.lawsAndRegulationIsLoading = true;
      })
      .addCase(getLawsAndRegulation.fulfilled, (state, action) => {
        state.lawsAndRegulation = action.payload;
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(getLawsAndRegulation.rejected, (state) => {
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(addLawsAndRegulation.pending, (state) => {
        state.lawsAndRegulationIsLoading = true;
      })
      .addCase(addLawsAndRegulation.fulfilled, (state) => {
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(addLawsAndRegulation.rejected, (state) => {
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(deleteLawsAndRegulation.pending, (state) => {
        state.lawsAndRegulationIsLoading = true;
      })
      .addCase(deleteLawsAndRegulation.fulfilled, (state) => {
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(deleteLawsAndRegulation.rejected, (state) => {
        state.lawsAndRegulationIsLoading = false;
      })
      .addCase(getContractualRequirements.pending, (state) => {
        state.contractualRequirementsIsLoading = true;
      })
      .addCase(getContractualRequirements.fulfilled, (state, action) => {
        state.contractualRequirements = action.payload;
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(getContractualRequirements.rejected, (state) => {
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(addContractualRequirement.pending, (state) => {
        state.contractualRequirementsIsLoading = true;
      })
      .addCase(addContractualRequirement.fulfilled, (state) => {
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(addContractualRequirement.rejected, (state) => {
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(deleteContractualRequirement.pending, (state) => {
        state.contractualRequirementsIsLoading = true;
      })
      .addCase(deleteContractualRequirement.fulfilled, (state) => {
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(deleteContractualRequirement.rejected, (state) => {
        state.contractualRequirementsIsLoading = false;
      })
      .addCase(getInterestedParties.pending, (state) => {
        state.interestedPartiesIsLoading = true;
      })
      .addCase(getInterestedParties.fulfilled, (state, action) => {
        state.interestedParties = action.payload;
        state.interestedPartiesIsLoading = false;
      })
      .addCase(getInterestedParties.rejected, (state) => {
        state.interestedPartiesIsLoading = false;
      })
      .addCase(addInterestedParties.pending, (state) => {
        state.interestedPartiesIsLoading = true;
      })
      .addCase(addInterestedParties.fulfilled, (state) => {
        state.interestedPartiesIsLoading = false;
      })
      .addCase(addInterestedParties.rejected, (state) => {
        state.interestedPartiesIsLoading = false;
      })
      .addCase(deleteInterestedParties.pending, (state) => {
        state.interestedPartiesIsLoading = true;
      })
      .addCase(deleteInterestedParties.fulfilled, (state) => {
        state.interestedPartiesIsLoading = false;
      })
      .addCase(deleteInterestedParties.rejected, (state) => {
        state.interestedPartiesIsLoading = false;
      })
      .addCase(getOrganizationUnits.pending, (state) => {
        state.organizationalUnitIsLoading = true;
      })
      .addCase(getOrganizationUnits.fulfilled, (state, action) => {
        state.organizationalUnit = action.payload;
        state.organizationalUnitIsLoading = false;
      })
      .addCase(getOrganizationUnits.rejected, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(addOrganizationUnit.pending, (state) => {
        state.organizationalUnitIsLoading = true;
      })
      .addCase(addOrganizationUnit.fulfilled, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(addOrganizationUnit.rejected, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(editOrganizationUnit.pending, (state) => {
        state.organizationalUnitIsLoading = true;
      })
      .addCase(editOrganizationUnit.fulfilled, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(editOrganizationUnit.rejected, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(deleteOrganizationUnit.pending, (state) => {
        state.organizationalUnitIsLoading = true;
      })
      .addCase(deleteOrganizationUnit.fulfilled, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(deleteOrganizationUnit.rejected, (state) => {
        state.organizationalUnitIsLoading = false;
      })
      .addCase(getOrganizationLocations.pending, (state) => {
        state.organizationLocationIsLoading = true;
      })
      .addCase(getOrganizationLocations.fulfilled, (state, action) => {
        state.organizationLocation = action.payload;
        state.organizationLocationIsLoading = false;
      })
      .addCase(getOrganizationLocations.rejected, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(addOrganizationLocation.pending, (state) => {
        state.organizationLocationIsLoading = true;
      })
      .addCase(addOrganizationLocation.fulfilled, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(addOrganizationLocation.rejected, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(editOrganizationLocation.pending, (state) => {
        state.organizationLocationIsLoading = true;
      })
      .addCase(editOrganizationLocation.fulfilled, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(editOrganizationLocation.rejected, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(deleteOrganizationLocation.pending, (state) => {
        state.organizationLocationIsLoading = true;
      })
      .addCase(deleteOrganizationLocation.fulfilled, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(deleteOrganizationLocation.rejected, (state) => {
        state.organizationLocationIsLoading = false;
      })
      .addCase(getOrganizationsCountries.pending, (state) => {
        state.organizationCountriesIsLoading = true;
      })
      .addCase(getOrganizationsCountries.fulfilled, (state, action) => {
        state.organizationCountries = action.payload;
        state.organizationCountriesIsLoading = false;
      })
      .addCase(getOrganizationsCountries.rejected, (state) => {
        state.organizationCountriesIsLoading = false;
      });
  },
});

export const selectGetOrganizationRoles = (state: RootState) => {
  const { organizationRoles } = state.organization;
  const { organizationIsLoading } = state.organization;

  return { organizationRoles, organizationIsLoading };
};
export const selectChangeOrganizationRolesIsLoading = (state: RootState) =>
  state.organization.changeOrganizationRolesIsLoading;
export const selectLawsAndRegulation = (state: RootState) => {
  const { lawsAndRegulationIsLoading } = state.organization;
  const { lawsAndRegulation } = state.organization;

  return { lawsAndRegulation, lawsAndRegulationIsLoading };
};
export const selectContractualRequirements = (state: RootState) => {
  const { contractualRequirements } = state.organization;
  const { contractualRequirementsIsLoading } = state.organization;

  return { contractualRequirements, contractualRequirementsIsLoading };
};
export const selectInterestedParties = (state: RootState) => {
  const { interestedParties, interestedPartiesIsLoading } = state.organization;

  return { interestedParties, interestedPartiesIsLoading };
};
export const selectOrganizationalUnits = (state: RootState) => {
  const { organizationalUnit, organizationalUnitIsLoading } = state.organization;

  return { organizationalUnit, organizationalUnitIsLoading };
};
export const selectOrganizationLocations = (state: RootState) => {
  const { organizationLocation, organizationLocationIsLoading } = state.organization;

  return { organizationLocation, organizationLocationIsLoading };
};
export const selectOrganizationCountries = (state: RootState) => {
  const { organizationCountries, organizationCountriesIsLoading } = state.organization;

  return { organizationCountries, organizationCountriesIsLoading };
};
export default organizationSlice.reducer;
