import { ClassificationEnum, CompliantEnum, FilterOptionType } from "./FilterTypes";

export const employeeFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "COMPLIANCE",
      name: "compliant",
      inputType: "checkbox",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
  ],
};

export const controlFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "COMPLIANCE",
      inputType: "checkbox",
      name: "compliant",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
    {
      groupTitle: "ASSET TYPES",
      inputType: "checkbox",
      name: "assetType",
      options: [
        // { checked: false, name: AssetTypesEnum.NonTechnica, label: AssetTypesEnum.NonTechnica },
        // { checked: false, name: AssetTypesEnum.Employees, label: AssetTypesEnum.Employees },
        // { checked: false, name: AssetTypesEnum.Accounts, label: AssetTypesEnum.Accounts },
        // { checked: false, name: AssetTypesEnum.Devices, label: AssetTypesEnum.Devices },
        // { checked: false, name: AssetTypesEnum.CloudResource, label: AssetTypesEnum.CloudResource },
      ],
    },
    {
      groupTitle: "OWNER",
      inputType: "select-option",
      name: "ownerId",
      selectData: [],
    },
  ],
};

export const deviceFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "CLASSIFICATION",
      inputType: "checkbox",
      name: "classificationLevel",
      options: [
        { checked: false, name: ClassificationEnum.Public, label: ClassificationEnum.Public },
        { checked: false, name: ClassificationEnum.Internal, label: ClassificationEnum.Internal },
        {
          checked: false,
          name: ClassificationEnum.Confidential,
          label: ClassificationEnum.Confidential,
        },
        {
          checked: false,
          name: ClassificationEnum.Restricted,
          label: ClassificationEnum.Restricted,
        },
        {
          checked: false,
          name: ClassificationEnum.NotApplicable,
          label: "Not Applicable",
        },
      ],
    },
    {
      groupTitle: "COMPLIANCE",
      inputType: "checkbox",
      name: "compliance",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
    {
      groupTitle: "OWNER",
      inputType: "select-option",
      name: "ownerId",
      selectData: [],
    },
    {
      groupTitle: "USER",
      inputType: "select-option",
      name: "userId",
      selectData: [],
    },
  ],
};

export const cloudFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "CLASSIFICATION",
      inputType: "checkbox",
      name: "classificationLevel",
      options: [
        {
          checked: false,
          name: ClassificationEnum.Public,
          label: ClassificationEnum.Public,
        },
        {
          checked: false,
          name: ClassificationEnum.Internal,
          label: ClassificationEnum.Internal,
        },
        {
          checked: false,
          name: ClassificationEnum.Confidential,
          label: ClassificationEnum.Confidential,
        },
        {
          checked: false,
          name: ClassificationEnum.Restricted,
          label: ClassificationEnum.Restricted,
        },
        {
          checked: false,
          name: ClassificationEnum.NotApplicable,
          label: ClassificationEnum.NotApplicable,
        },
      ],
    },
    {
      groupTitle: "COMPLIANCE",
      inputType: "checkbox",
      name: "compliance",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
    {
      groupTitle: "OWNER",
      inputType: "select-option",
      name: "ownerId",
      selectData: [],
    },
  ],
};

export const accountFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "COMPLIANCE",
      inputType: "checkbox",
      name: "compliance",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
    {
      groupTitle: "EMPLOYEE",
      inputType: "select-option",
      name: "employeeId",
      selectData: [],
    },
  ],
};

export const vulnerabilitiesFilter: {
  options: FilterOptionType[];
} = {
  options: [],
};

export const vendorsFilter: {
  options: FilterOptionType[];
} = {
  options: [],
};

export const risksFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "Inherent risk level",
      inputType: "checkbox",
      name: "inherent_risk_level",
      options: [
        { checked: false, name: "low", label: "Low" },
        { checked: false, name: "medium", label: "Medium" },
        { checked: false, name: "high", label: "High" },
      ],
    },
    {
      groupTitle: "Residual risk level",
      inputType: "checkbox",
      name: "residual_risk_level",
      options: [
        { checked: false, name: "low", label: "Low" },
        { checked: false, name: "medium", label: "Medium" },
        { checked: false, name: "high", label: "High" },
      ],
    },
    {
      groupTitle: "Treatment",
      inputType: "checkbox",
      name: "treatment ",
      options: [
        { checked: false, name: "accept", label: "Accept" },
        { checked: false, name: "transfer", label: "Transfer" },
        { checked: false, name: "mitigate", label: "Mitigate" },
        { checked: false, name: "avoid", label: "Avoid" },
      ],
    },
    {
      groupTitle: "COMPLIANCE",
      inputType: "checkbox",
      name: "compliance",
      options: [
        { checked: false, name: "compliant", label: CompliantEnum.Compliant },
        { checked: false, name: "Not Compliant", label: CompliantEnum.NotCompliant },
      ],
    },
    {
      groupTitle: "Risk priority level",
      inputType: "checkbox",
      name: "risk_priority_level",
      options: [
        { checked: false, name: "lowest", label: "Lowest" },
        { checked: false, name: "low", label: "Low" },
        { checked: false, name: "medium", label: "Medium" },
        { checked: false, name: "high", label: "High" },
        { checked: false, name: "highest", label: "Highest" },
      ],
    },
  ],
};

export const riskCatalogueFilter: {
  options: FilterOptionType[];
} = {
  options: [
    {
      groupTitle: "REGISTRATION",
      inputType: "checkbox",
      name: "registeredRisk",
      options: [
        { checked: false, name: "registered", label: "Registered Risk" },
        { checked: false, name: "Not registered", label: "Not Registered Risk" },
      ],
    },
    {
      groupTitle: "RISK Category",
      inputType: "select-option",
      name: "riskCategory",
      selectData: [],
    },
  ],
};
