import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  Configuration,
  JWTSuccessResponse,
  RegisterApi,
  VersionApi,
  VersionDetails,
  WhoAmIUser,
} from "mycio-openapi";
import { getLocalStorageUserData, setLocalStorageUserData } from "utils/data/user";
import Cookies from "js-cookie";
import type { RootState } from ".";

export interface AmplifyUserAttributes {
  email: string;
  email_verified: boolean;
  name: string;
  sub: string;
}

export interface AmplifyUser {
  attributes: AmplifyUserAttributes;
  username: string;
  id: string;
  challengeName?: string;
}

export const logout = createAction("application/logout");

export const getUser = createAsyncThunk("application/getUser", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  let accessToken = Cookies.get("token");
  let refreshAccessToken = Cookies.get("refresh_token");

  if (!accessToken && !refreshAccessToken) {
    return rejectWithValue({
      data: null,
      tenantAvailable: false,
      idToken: "",
    });
  }

  if (!accessToken && refreshAccessToken) {
    const api = new RegisterApi({} as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

    const response = (await api.registerRefreshTokenPost({ refresh_token: refreshAccessToken }))
      .data as JWTSuccessResponse;
    const { jwt, refresh, jwt_expiry, refresh_expiry } = response;

    accessToken = jwt;
    refreshAccessToken = refresh;

    if (jwt && refresh_expiry && jwt_expiry && refresh) {
      Cookies.set("token", jwt, { expires: new Date(jwt_expiry) });
      Cookies.set("refresh_token", refresh, { expires: new Date(refresh_expiry) });
    }
  }

  const api = new RegisterApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

  const whoamiData = await api.registerWhoamiGet();

  const authUser = whoamiData.data;

  const { data } = await api.registerUserEmailStatusGet(authUser.email as string);

  if (data.status === "CONFIRMED" && accessToken)
    return {
      data: authUser,
      tenantAvailable: true,
      idToken: accessToken,
    };

  return {
    data: authUser,
    tenantAvailable: false,
    idToken: accessToken,
  };
});

export const getVersion = createAsyncThunk("application/getVersion", async (_, { getState }) => {
  const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
  const api = new VersionApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
  let version: VersionDetails = {};
  try {
    const response = await api.versionGet();
    version = response.data ?? {};
  } catch {
    version = {};
  }
  return version;
});

interface CurrentUser {
  data: null | WhoAmIUser;
  isLoading: boolean;
  isTenantAvailable: boolean;
  userToken: string;
}

export const setCurrentUser = createAction(
  "application/setCurrentUser",
  (formData: CurrentUser) => ({
    payload: {
      formData,
    },
  })
);

export const setSidebarOpen = createAction("application/setSidebarOpen", (formData: boolean) => ({
  payload: {
    formData,
  },
}));

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    authenticated: false,
    currentUser: {
      data: null,
      isLoading: true,
      isTenantAvailable: false,
      userToken: "",
    } as CurrentUser,
    passwordRequirements: {
      minLength: 8,
      pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{4,}$",
    },
    versionInfo: {} as VersionDetails,
    sideBarOpen: getLocalStorageUserData("SideBarOpen") === "true",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.currentUser.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.currentUser.data = action.payload.data;
        state.currentUser.isTenantAvailable = action.payload.tenantAvailable;
        state.currentUser.isLoading = false;
        state.currentUser.userToken = action.payload.idToken ?? "";
        state.authenticated = true;
      })
      .addCase(getUser.rejected, (state) => {
        state.currentUser.isLoading = false;
        state.authenticated = false;
      })
      .addCase("application/logout", (state) => {
        state.currentUser.userToken = "";
        state.authenticated = false;
        state.currentUser.isLoading = false;
        state.currentUser.data = null;
        state.currentUser.isTenantAvailable = false;
        Cookies.remove("token");
        Cookies.remove("refresh_token");
      })
      .addCase(getVersion.fulfilled, (state, action) => {
        state.versionInfo = action.payload;
      })
      .addCase(setCurrentUser, (state, action) => {
        state.currentUser = action.payload.formData;
      })
      .addCase(setSidebarOpen, (state, action) => {
        state.sideBarOpen = action.payload.formData;
        setLocalStorageUserData("SideBarOpen", action.payload.formData.toString());
      });
  },
});
export default applicationSlice.reducer;

export const getToken = (state: RootState) => state.application.currentUser.userToken;

export const isAuthenticated = (state: RootState) => state.application.authenticated;

export const selectVersion = (state: RootState) => state.application.versionInfo;

export const selectCurrentUser = (state: RootState) => state.application.currentUser;

export const selectSidebarOpen = (state: RootState) => state.application.sideBarOpen;
