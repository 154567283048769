import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  Configuration,
  EvidenceApi,
  Evidences,
  FilesApi,
  RelationshipApi,
  RelationshipDestinationTypeEnum,
  Relationships,
} from "mycio-openapi";
import { showLoadingToast, updateToast } from "utils/toasts";
import {
  evidenceUploadFailureMessage,
  evidenceDownloadFailureMessage,
  evidenceDownloadLoadingMessage,
  evidenceDownloadSuccessMessage,
  evidenceUploadLoadingMessage,
  evidenceUploadSuccessMessage,
} from "utils/toasts/messages";

export const getAllEvidences = createAsyncThunk(
  "evidence/getAllEvidences",
  async (_, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new EvidenceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let evidence: Evidences = [];
    try {
      const response = await api.evidenceGet();
      evidence = response.data?.document_list ?? [];
    } catch {
      evidence = [];
    }
    return evidence;
  }
);

export const linkEvidence = createAsyncThunk(
  "evidence/linkEvidence",
  async (
    args: {
      controlUUID: string;
      evidenceUUIDs: string[] | string;
    },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const relationshipAPI = new RelationshipApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { controlUUID, evidenceUUIDs } = args;
    if (Array.isArray(evidenceUUIDs))
      evidenceUUIDs.map((evidence) =>
        relationshipAPI.relationshipPost({
          SourceUUID: controlUUID,
          DestinationUUID: evidence,
          DestinationType: RelationshipDestinationTypeEnum.Evidence,
        })
      );
    else
      void relationshipAPI.relationshipPost({
        SourceUUID: controlUUID,
        DestinationUUID: evidenceUUIDs,
        DestinationType: RelationshipDestinationTypeEnum.Evidence,
      });
  }
);

export const downloadEvidence = createAsyncThunk(
  "evidence/downloadEvidence",
  async (
    args: {
      evidenceUUID: string;
    },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new FilesApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const { evidenceUUID } = args;
    const id = showLoadingToast(evidenceDownloadLoadingMessage);
    try {
      const response = await api.filesEvidenceEvidenceUUIDGet(evidenceUUID);
      const file = new Blob([Buffer.from(response.data.name, "base64")], {
        type: response.headers["content-type"],
      });
      updateToast(id, {
        render: evidenceDownloadSuccessMessage,
        type: "success",
        isLoading: false,
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch {
      updateToast(id, {
        render: evidenceDownloadFailureMessage,
        type: "error",
        isLoading: false,
      });
    }
  }
);

export const unlinkEvidence = createAsyncThunk(
  "evidence/unlinkEvidence",
  async (
    args: {
      controlUUID: string;
      evidenceUUIDs: string[] | string;
    },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const relationshipAPI = new RelationshipApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { controlUUID, evidenceUUIDs } = args;
    if (Array.isArray(evidenceUUIDs))
      evidenceUUIDs.map((evidence) =>
        relationshipAPI.relationshipSourceIdDestinationIdDelete(controlUUID, evidence)
      );
    else void relationshipAPI.relationshipSourceIdDestinationIdDelete(controlUUID, evidenceUUIDs);
  }
);

export const uploadEvidence = createAsyncThunk(
  "evidence/uploadEvidence",
  async (
    args: {
      file: File;
      controlUUID: string;
    },
    { getState, dispatch }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const id = showLoadingToast(evidenceUploadLoadingMessage);
    const api = new EvidenceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { file, controlUUID } = args;
    try {
      await api.evidencePost(file).then(async (response) => {
        if (response.status === 200 && response.data.UUID) {
          dispatch(linkEvidence({ controlUUID, evidenceUUIDs: [response.data.UUID] }))
            .then(() => {
              updateToast(id, {
                render: evidenceUploadSuccessMessage,
                type: "success",
                isLoading: false,
              });
            })
            .catch(() => {
              updateToast(id, {
                render: evidenceUploadFailureMessage,
                type: "error",
                isLoading: false,
              });
            });
        }
      });
    } catch {
      updateToast(id, {
        render: evidenceUploadFailureMessage,
        type: "error",
        isLoading: false,
      });
    }
  }
);

export const getLinkedEvidences = createAsyncThunk(
  "evidence/getLinkedEvidences",
  async (
    args: {
      controlUUID: string;
    },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new RelationshipApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { controlUUID } = args;
    let evidenceRel: Relationships = [];
    try {
      const response = await api.relationshipSourceUUIDDestinationTypeGet(
        controlUUID,
        RelationshipDestinationTypeEnum.Evidence
      );
      evidenceRel = response.data ?? [];
    } catch {
      evidenceRel = [];
    }
    return evidenceRel;
  }
);

export const evidencesSlice = createSlice({
  name: "evidences",
  initialState: {
    loading: false,
    value: [] as Evidences,
    linkedEvidenceUUIDs: [] as (string | undefined)[],
    linkedEvidenceIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvidences.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEvidences.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loading = false;
      })
      .addCase(getAllEvidences.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLinkedEvidences.fulfilled, (state, action) => {
        state.linkedEvidenceIsLoading = false;
        state.linkedEvidenceUUIDs = action.payload.map((rel) => rel.UUID as string);
      })
      .addCase(unlinkEvidence.pending, (state, action) => {
        const arrayOfEvidence = Array.isArray(action.meta.arg.evidenceUUIDs)
          ? [...action.meta.arg.evidenceUUIDs]
          : [action.meta.arg.evidenceUUIDs];
        state.linkedEvidenceUUIDs = state.linkedEvidenceUUIDs.filter(
          (ev) => !arrayOfEvidence.includes(ev as string)
        );
      })
      .addCase(linkEvidence.pending, (state) => {
        state.linkedEvidenceIsLoading = true;
      })
      .addCase("application/logout", (state) => {
        state.value = [];
        state.linkedEvidenceUUIDs = [];
      });
  },
});

export default evidencesSlice.reducer;

export const selectAllEvidences = (state: RootState) => state.evidences.value;
export const selectLinkedEvidences = (state: RootState) =>
  state.evidences.value.filter((evidence) =>
    state.evidences.linkedEvidenceUUIDs.includes(evidence.id?.toString())
  );
export const selectEvidencesIsLoading = (state: RootState) => state.evidences.loading;
export const selectLinkedEvidenceIsLoading = (state: RootState) =>
  state.evidences.linkedEvidenceIsLoading;
