import { Amplify } from "aws-amplify";
import messages from "lang/compiled/en.json";
import type { AppProps } from "next/app";
import Script from "next/script";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import store from "store";
import { hotjar } from "react-hotjar";
import "styles/antd.less";
import "styles/globals.css";
import { getRumFunction } from "utils/rum";
import { Loading } from "components/Loading";
import AuthListener from "components/auth/AuthListener";
import Router from "next/router";
import { configure, start, done } from "nprogress";
import "../styles/Nprogress.css";
import "../styles/matrix.css";
import { ToastContainer } from "react-toastify";

configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => start());
Router.events.on("routeChangeComplete", () => done());
Router.events.on("routeChangeError", () => done());

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID,
    identityPoolId: undefined,
    // REQUIRED - Amazon Cognito Region
    region: process.env.NEXT_PUBLIC_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.NEXT_PUBLIC_USERPOOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.NEXT_PUBLIC_USERPOOL_WEBCLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    signUpVerificationMethod: "code",
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "prod") {
      hotjar.initialize(
        Number(process.env.NEXT_PUBLIC_HOTJAR_ID),
        Number(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION)
      );
    }
  }, []);

  const rumFunction = getRumFunction();

  return (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <Head>
        <title>Skeldus</title>
        <link rel="icon" type="image/png" sizes="32x32" href="/skeldusL.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/skeldusL.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/skeldusL.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="title" content="SKELDUS Platform" />
        <meta name="description" content="Build a secure company with skeldus." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.mycio.io/" />
        <meta property="og:title" content="SKELDUS Platform" />
        <meta property="og:description" content="Build a secure company with skeldus." />
        <meta property="og:image" content="/skeldusLOGO.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://app.mycio.io/" />
        <meta property="twitter:title" content="SKELDUS Platform" />
        <meta property="twitter:description" content="Build a secure company with skeldus." />
        <meta property="twitter:image" content="/skeldusLOGO.png" />
        <meta name="robots" content="noindex" />
      </Head>

      {rumFunction !== "" && (
        <Script
          id="cloudwatch-rum"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: rumFunction,
          }}
        />
      )}

      <Provider store={store}>
        <div id="mycio-modal" />
        <AuthListener setLoading={setLoading} />
        {loading ? (
          <Loading />
        ) : (
          <div className="font-satoshi text-base">
            <Component {...pageProps} />
            <ToastContainer />
          </div>
        )}
      </Provider>
    </IntlProvider>
  );
}

export default MyApp;
