import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IntegrationPlatform } from "utils/data/interfaces";
import type { RootState } from "store";
import {
  Agent,
  AgentApi,
  AgentRecommended,
  Configuration,
  MycioConfigApi,
  MyCIOConfiguration,
  FilesApi,
  TenantConfig,
  UserConfig,
} from "mycio-openapi";
import { showErrorToast } from "utils/toasts";
import { recommendedAgentsFetchFailureMessage } from "utils/toasts/messages";
// import { updateUser } from "./users";
import { getFeatureFlags } from "./featureFlags";

interface UserConfigWorkaround extends Omit<UserConfig, "UUID"> {
  UUID: string;
  CognitoID?: string | number;
  TenantUUID?: string | number;
  invitedUsersMessage?: Record<string, string>;
}

interface TenantConfigWorkaround extends TenantConfig {
  Agents?: Agent[];
  AgentLinks?: {
    UUID: string;
    platform: IntegrationPlatform;
    URL: string;
  }[];
}

export const getConfig = createAsyncThunk("config/getConfig", async (_, thunkAPI) => {
  const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
  const api = new MycioConfigApi(
    { accessToken } as Configuration,
    process.env.NEXT_PUBLIC_MYCIO_API
  );
  const response: MyCIOConfiguration = (await api.mycioConfigsGet()).data;
  return response;
});

export const getRecommendedAgents = createAsyncThunk(
  "config/getRecommendedAgents",
  async (_, { getState }) => {
    const idToken = (getState() as RootState).application.currentUser.userToken;
    const api = new AgentApi(
      { accessToken: idToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let response: AgentRecommended = [];
    try {
      const { data } = await api.agentRecommendedGet();
      response = data;
    } catch {
      showErrorToast(recommendedAgentsFetchFailureMessage);
    }
    return response;
  }
);

export const initConfig = createAsyncThunk("config/init", async (params, { dispatch }) => {
  // await dispatch(getRecommendedAgents());
  await dispatch(getFeatureFlags());
});

export const getAgentDownloadLink = createAsyncThunk(
  "config/getAgentDownloadLink",
  async (args: { UUID: string }, { getState }) => {
    const idToken = (getState() as RootState).application.currentUser.userToken;
    const api = new FilesApi(
      { accessToken: idToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const { data } = await api.filesAgentAgentUUIDPresignGet(args.UUID);
    return data;
  }
);

export const getDownloadLinksForAllAgents = createAsyncThunk(
  "config/getDownloadLinksAllAgents",
  async (_, { getState, dispatch }) => {
    (getState() as RootState).config.tenantConfig.Agents?.forEach((agent) => {
      void dispatch(getAgentDownloadLink({ UUID: agent?.UUID ?? "" }));
    });
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState: {
    userConfig: {} as UserConfigWorkaround,
    tenantConfig: {} as TenantConfigWorkaround,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.tenantConfig.Name = action.payload.TenantConfig.Name;
        state.tenantConfig.MyCIOAgent = action.payload.TenantConfig.MyCIOAgent;
        state.userConfig = action.payload.UserConfig as UserConfigWorkaround;
        state.isLoading = false;
      })
      .addCase(getConfig.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRecommendedAgents.fulfilled, (state, action) => {
        state.tenantConfig.Agents = action.payload as unknown as Agent[];
        state.tenantConfig.AgentLinks = [];
      })
      .addCase(getAgentDownloadLink.fulfilled, (state, action) => {
        const index2 =
          state.tenantConfig.AgentLinks?.findIndex(
            (agent) => agent.UUID === action.meta.arg.UUID
          ) ?? -1;

        if (index2 >= 0 && state.tenantConfig.AgentLinks) {
          state.tenantConfig.AgentLinks[index2] = {
            ...state.tenantConfig.AgentLinks[index2],
            URL: action.payload.URL ?? "",
          };
        } else {
          state.tenantConfig.AgentLinks?.push({
            URL: action.payload?.URL ?? "",
            UUID: action.meta.arg.UUID,
            platform: state.tenantConfig.Agents?.find(
              (agent) => agent.UUID === action.meta.arg.UUID
            )?.Platform as IntegrationPlatform,
          });
        }
      })
      .addCase("application/logout", (state) => {
        state.tenantConfig = {} as TenantConfigWorkaround;
        state.userConfig = {} as UserConfigWorkaround;
      });
    // TODO(MCT-489): This needs to be updated in both openAPI and accordingly here to update user
    // .addCase(updateUser.fulfilled, (state, action) => {
    //   state.userConfig = { ...state.userConfig, ...action.meta.arg.user };
    // });
  },
});
export default configSlice.reducer;

export const initConfigLoading = (state: RootState) => state.config.isLoading;

export const isAgentEnabled = (state: RootState) => state.config.tenantConfig.MyCIOAgent;
