import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cloudFilter } from "components/FilterSidebar/FilterOptions";
import isString from "lodash/isString";

import type { RootState } from "store";
import {
  Configuration,
  Controls,
  ResourceApi,
  Resources,
  Resource,
  ResourceExemptDetails,
  ControlResourceRelationships,
  AccountsApi,
  CloudAccount,
  EmployeesApi,
  FailedResponse,
  EmployeesResponse,
  Employee,
  AccountsIdControlsGet200Response,
  TenantEmployeesEmployeeIdOffBoardPatchRequest,
  EmployeePatch,
  TenantEmployeesPostRequest,
  TenantEmployeesGetSearchByEnum,
  DevicesApi,
  DevicesResponse,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";
import { devicesFetchFailureMessage, awsFetchFailureMessage } from "utils/toasts/messages";

interface FilterType {
  classification?: string[];
  compliance?: string[];
  owner?: string;
  type?: string;
  user?: string;
  otherSearch?: string[];
  accountSearch?: string;
}

interface Filter {
  values?: string[];
  search?: string;
}

interface PaginationPayload {
  searchBy?: TenantEmployeesGetSearchByEnum;
  searchKeyword?: string;
  page?: number;
  limit?: number;
  compliant?: boolean;
}
interface PaginationPayloadById {
  employeeId: number;
  page?: number;
  limit?: number;
}

export const getDevices = createAsyncThunk<DevicesResponse, PaginationPayload>(
  "resources/getDevices",
  async (arg, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.application.currentUser.userToken;
      const api = new DevicesApi(
        new Configuration({ accessToken }),
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.devicesGet(arg.page, arg.limit);
      return response.data;
    } catch (error) {
      showErrorToast(devicesFetchFailureMessage);
      throw error;
    }
  }
);

export const getEmployees = createAsyncThunk<EmployeesResponse, PaginationPayload>(
  "resources/getEmployees",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new EmployeesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.tenantEmployeesGet(
        arg.searchBy,
        arg.searchKeyword,
        arg.page,
        arg.limit,
        arg.compliant
      );
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEmployeeById = createAsyncThunk<Employee, PaginationPayloadById>(
  "resources/getEmployeeById",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new EmployeesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.tenantEmployeesEmployeeIdGet(arg.employeeId)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editEmployee = createAsyncThunk<
  Employee,
  { employeeId: number; employeePatch: EmployeePatch }
>("resources/editEmployee", async (args, { getState, rejectWithValue }) => {
  const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
  const api = new EmployeesApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

  try {
    const response = (await api.tenantEmployeesEmployeeIdPatch(args.employeeId, args.employeePatch))
      .data;
    showSuccessToast("Employee updated successfully");
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong..");
    return rejectWithValue("Something went wrong");
  }
});

export const getEmployeeControlsById = createAsyncThunk<
  AccountsIdControlsGet200Response,
  PaginationPayloadById
>("resources/getEmployeeControlsById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new EmployeesApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (
      await api.tenantEmployeesEmployeeIdControlsGet(arg?.employeeId, arg?.page, arg?.limit)
    ).data;

    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const addEmployees = createAsyncThunk(
  "resources/addEmployees",
  async (
    args: {
      employeesList: File;
    },
    { getState, rejectWithValue }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new EmployeesApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      await api.tenantEmployeesListPost(args.employeesList);
      showSuccessToast("Employees added successfully");
      return true;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const patchEmployeesOffBoard = createAsyncThunk<
  any,
  { id: number; endDate: TenantEmployeesEmployeeIdOffBoardPatchRequest }
>("resources/patchEmployeesOffBoard", async (args, { getState, rejectWithValue }) => {
  const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
  const api = new EmployeesApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

  try {
    await api.tenantEmployeesEmployeeIdOffBoardPatch(args.id, args.endDate);
    showSuccessToast("Employees patched successfully");
    return true;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong..");
    return rejectWithValue("Something went wrong");
  }
});

export const addEmployee = createAsyncThunk<Employee, TenantEmployeesPostRequest>(
  "resources/addEmployee",
  async (args, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new EmployeesApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const response = (await api.tenantEmployeesPost(args)).data;
      showSuccessToast("Employee added successfully");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAWSResources = createAsyncThunk(
  "resources/getAWSResources",
  async (_, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let awsResources: Resources = [];
    try {
      const response = await api.resourcesGet("AWS");
      awsResources = response.data ?? [];
    } catch {
      showErrorToast(awsFetchFailureMessage);
    }
    return awsResources;
  }
);

export const getAzureResources = createAsyncThunk(
  "resources/getAzureResources",
  async (_, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let azureResources: Resources = [];
    try {
      const response = await api.resourcesGet("AZURE");
      azureResources = response.data ?? [];
    } catch {
      showErrorToast(awsFetchFailureMessage);
    }
    return azureResources;
  }
);
export type CloudResourceType = "DEVICES" | "AWS" | "AZURE" | "AZUREAD" | "EMPLOYEE";

export const getCloudResources = createAsyncThunk(
  "resources/getCloudResources",
  async (type: CloudResourceType, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let cloudResources: Resources = [];
    try {
      const response = await api.resourcesGet(type);
      cloudResources = response.data ?? [];
    } catch {
      showErrorToast("Failed to fetch cloud assets");
    }
    return cloudResources;
  }
);

export const setSearchFilterObject = createAction(
  "resources/setSearchFilterObject",
  (searchFilterObject: FilterType) => ({
    payload: {
      searchFilterObject,
    },
  })
);

export const selectedCloudResource = createAction(
  "resources/selectedCloudResource",
  (selectedResource: Resource) => ({
    payload: {
      selectedResource,
    },
  })
);

export const selectedCloudAccount = createAction(
  "cloudAccounts/selectedCloudAccount",
  (selectedAccount: CloudAccount) => ({
    payload: {
      selectedAccount,
    },
  })
);

export const selectedDevice = createAction(
  "resources/selectedDevice",
  (selectedDevices: Resource) => ({
    payload: {
      selectedDevices,
    },
  })
);

export const setDeviceFilter = createAction("resources/setDeviceFilter", (filters?: Filter) => ({
  payload: {
    filters,
  },
}));

export const setCloudResourceFilter = createAction(
  "resources/setCloudResourceFilter",
  (filters?: Filter) => ({
    payload: {
      filters,
    },
  })
);

export const setEmployeeFilter = createAction(
  "resources/setEmployeeFilter",
  (filters?: Filter) => ({
    payload: {
      filters,
    },
  })
);

export const getResourceControls = createAsyncThunk(
  "resources/getResourceControls",
  async (resourceUUID: string, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let controls: Controls = [];
    try {
      const response = await api.resourcesResourceUUIDControlsGet(resourceUUID);
      controls = response.data;
    } catch {
      controls = [];
    }
    return controls;
  }
);

export const updateResourceExempt = createAsyncThunk(
  "resources/updateResourceExempt",
  async (
    args: { payload: { resourceUUID: string; resourceExemptDetails: ResourceExemptDetails } },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let exemptControl = {};
    try {
      const { payload } = args;
      const response = await api.resourcesResourceUUIDExemptPut(
        payload.resourceUUID,
        payload.resourceExemptDetails
      );
      exemptControl = response.data ?? {};
    } catch {
      exemptControl = {};
    }
    return exemptControl;
  }
);

export const updateResourceOwner = createAsyncThunk(
  "resources/updateResourceOwner",
  async (args: { payload: { resourceUUID: string; ownerUUID: string } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let ownerData = {};
    try {
      const { payload } = args;
      const response = await api.resourcesResourceUUIDChangeOwnerOwnerUUIDPut(
        payload.resourceUUID,
        payload.ownerUUID
      );
      ownerData = response.data ?? {};
    } catch {
      ownerData = {};
    }
    return ownerData;
  }
);

export const updateCloudAccountOwner = createAsyncThunk(
  "cloudAccounts/updateCloudAccountOwner",
  async (args: { payload: { cloudAccountUUID: string; ownerUUID: string } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let ownerData: CloudAccount = {};
    try {
      const { payload } = args;
      const response = await api.cloudAccountsCloudAccountUUIDChangeOwnerOwnerUUIDPut(
        payload.cloudAccountUUID,
        payload.ownerUUID
      );
      ownerData = response.data.data ?? {};
    } catch {
      ownerData = {};
    }
    return ownerData;
  }
);

export const updateResourceClassification = createAsyncThunk(
  "resources/updateResourceClassification",
  async (args: { payload: { resourceUUID: string; classification: number } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let resources = {};
    try {
      const { payload } = args;
      const response = await api.resourcesResourceUUIDChangedataclassificationClassificationPut(
        payload.resourceUUID,
        payload.classification
      );
      resources = response.data ?? {};
    } catch {
      resources = {};
    }
    return resources;
  }
);

export const updateCloudAccountClassification = createAsyncThunk(
  "cloudAccounts/updateCloudAccountClassification",
  async (args: { payload: { cloudAccountUUID: string; classification: number } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let resources = {};
    try {
      const { payload } = args;
      const response =
        await api.cloudAccountsCloudAccountUUIDChangedataclassificationClassificationPut(
          payload.cloudAccountUUID,
          payload.classification
        );
      resources = response.data.data ?? {};
    } catch {
      resources = {};
    }
    return resources;
  }
);

export const deleteResource = createAsyncThunk(
  "resources/deleteResource",
  async (resourceUUID: string, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let controls = {};
    try {
      const response = await api.resourcesResourceUUIDDelete(resourceUUID);
      controls = response.data ?? {};
    } catch {
      controls = {};
    }
    return controls;
  }
);

export const getComplianceStatus = createAsyncThunk(
  "resources/getComplianceStatus",
  async (resourceUUID: string, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let complianceStatus: ControlResourceRelationships = [];
    try {
      const response = await api.resourcesResourceUUIDCompliancestatusGet(resourceUUID);
      complianceStatus = response.data ?? [];
    } catch {
      complianceStatus = [];
    }
    return complianceStatus;
  }
);

export const notifyResourceOwner = createAsyncThunk(
  "resources/notifyResourceOwner",
  async (resourceUUID: string, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ResourceApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let resources = {};
    try {
      const response = await api.resourcesResourceUUIDNotifyPost(resourceUUID);
      resources = response.data ?? {};
    } catch {
      resources = {};
    }
    return resources;
  }
);

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: {
    devices: {} as DevicesResponse,
    devicesIsLoading: false,
    awsResources: [] as Resources,
    awsResourcesIsLoading: false,
    searchFilterObject: {} as FilterType,
    resourceControls: {
      value: [] as Controls,
      isLoading: false,
    },
    cloudResources: {
      value: [] as Resources,
      isLoading: false,
    },
    resourceExemptStatusIsLoading: false,
    updateResourceOwnerIsLoading: false,
    updateResourceClassificationIsLoading: false,
    deleteResourcesIsLoading: false,
    selectedResource: {} as Resource,
    selectedDevice: {} as Resource,
    complianceStatus: [] as ControlResourceRelationships,
    complianceStatusLoading: false,
    notifyOwnerStatusLoading: false,
    filteredData: [] as Resources,
    updateCloudAccountsIsLoading: false,
    updateCloudAccountClassificationData: {} as CloudAccount,
    updateCloudAccountOwnerIsLoading: false,
    selectedAccount: {} as CloudAccount,
    updateCloudAccountOwnerData: {} as CloudAccount,
    azureResources: [] as Resources,
    azureResourcesIsLoading: false,
    deviceFilter: {
      search: "",
      values: [],
    } as Filter,
    cloudResourceFilter: {
      search: "",
      values: [],
    } as Filter,
    employees: {
      isLoading: false,
      value: {} as EmployeesResponse,
      filters: {
        values: [],
        search: "",
      } as Filter,
    },
    employee: {
      isLoading: false,
      value: {} as Employee,
      employeeOffBoardIsLoading: false,
    },
    employeeControls: {
      employeeControls: {} as AccountsIdControlsGet200Response,
      isLoading: false,
    },
    editEmployeeIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDevices.pending, (state) => {
        state.devicesIsLoading = true;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.devices = action.payload;
        state.devicesIsLoading = false;
      })
      .addCase(getDevices.rejected, (state) => {
        state.devices = {};
        state.devicesIsLoading = false;
      })
      .addCase(getAWSResources.pending, (state) => {
        state.awsResourcesIsLoading = true;
      })
      .addCase(getAWSResources.fulfilled, (state, action) => {
        state.awsResources = action.payload;
        state.awsResourcesIsLoading = false;
      })
      .addCase(getAWSResources.rejected, (state) => {
        state.awsResources = [];
        state.awsResourcesIsLoading = false;
      })
      .addCase(getAzureResources.pending, (state) => {
        state.azureResourcesIsLoading = true;
      })
      .addCase(getAzureResources.fulfilled, (state, action) => {
        state.azureResources = action.payload;
        state.azureResourcesIsLoading = false;
      })
      .addCase(getAzureResources.rejected, (state) => {
        state.azureResources = [];
        state.azureResourcesIsLoading = false;
      })
      .addCase(setSearchFilterObject, (state, action) => {
        state.searchFilterObject = action.payload.searchFilterObject;
      })
      .addCase(getResourceControls.pending, (state) => {
        state.resourceControls.isLoading = true;
      })
      .addCase(getResourceControls.fulfilled, (state, action) => {
        state.resourceControls.value = action.payload;
        state.resourceControls.isLoading = false;
      })
      .addCase(getResourceControls.rejected, (state) => {
        state.resourceControls.value = [];
        state.resourceControls.isLoading = false;
      })
      .addCase(updateResourceExempt.pending, (state) => {
        state.resourceExemptStatusIsLoading = true;
      })
      .addCase(updateResourceExempt.fulfilled, (state) => {
        state.resourceExemptStatusIsLoading = false;
      })
      .addCase(updateResourceExempt.rejected, (state) => {
        state.resourceExemptStatusIsLoading = false;
      })
      .addCase(updateResourceOwner.pending, (state) => {
        state.updateResourceOwnerIsLoading = true;
      })
      .addCase(updateResourceOwner.fulfilled, (state, action) => {
        state.selectedResource = action.payload as Resource;
        state.updateResourceOwnerIsLoading = false;
      })
      .addCase(updateResourceOwner.rejected, (state) => {
        state.updateResourceOwnerIsLoading = false;
      })
      .addCase(updateResourceClassification.pending, (state) => {
        state.updateResourceClassificationIsLoading = true;
      })
      .addCase(updateResourceClassification.fulfilled, (state, action) => {
        state.selectedResource = action.payload as Resource;
        state.updateResourceClassificationIsLoading = false;
      })
      .addCase(updateResourceClassification.rejected, (state) => {
        state.updateResourceClassificationIsLoading = false;
      })
      .addCase(deleteResource.pending, (state) => {
        state.deleteResourcesIsLoading = true;
      })
      .addCase(deleteResource.fulfilled, (state) => {
        state.deleteResourcesIsLoading = false;
      })
      .addCase(deleteResource.rejected, (state) => {
        state.deleteResourcesIsLoading = false;
      })
      .addCase(selectedCloudResource, (state, action) => {
        state.selectedResource = action.payload.selectedResource;
      })
      .addCase(selectedDevice, (state, action) => {
        state.selectedDevice = action.payload.selectedDevices;
      })
      .addCase(getComplianceStatus.pending, (state) => {
        state.complianceStatusLoading = true;
      })
      .addCase(getComplianceStatus.fulfilled, (state, action) => {
        state.complianceStatus = action.payload;
        state.complianceStatusLoading = false;
      })
      .addCase(getComplianceStatus.rejected, (state) => {
        state.complianceStatus = [];
        state.complianceStatusLoading = false;
      })
      .addCase(notifyResourceOwner.pending, (state) => {
        state.notifyOwnerStatusLoading = true;
      })
      .addCase(notifyResourceOwner.fulfilled, (state) => {
        state.notifyOwnerStatusLoading = false;
      })
      .addCase(notifyResourceOwner.rejected, (state) => {
        state.notifyOwnerStatusLoading = false;
      })
      .addCase(updateCloudAccountClassification.pending, (state) => {
        state.updateCloudAccountsIsLoading = true;
      })
      .addCase(updateCloudAccountClassification.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
        state.updateCloudAccountsIsLoading = false;
      })
      .addCase(updateCloudAccountClassification.rejected, (state) => {
        state.updateCloudAccountsIsLoading = false;
      })
      .addCase(updateCloudAccountOwner.pending, (state) => {
        state.updateCloudAccountOwnerIsLoading = true;
      })
      .addCase(updateCloudAccountOwner.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
        state.updateCloudAccountOwnerIsLoading = false;
      })
      .addCase(updateCloudAccountOwner.rejected, (state) => {
        state.updateCloudAccountOwnerIsLoading = false;
      })
      .addCase(selectedCloudAccount, (state, action) => {
        state.selectedAccount = action.payload.selectedAccount;
      })
      .addCase(setDeviceFilter, (state, action) => {
        if (action.payload.filters?.values)
          state.deviceFilter.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.deviceFilter.search = action.payload.filters?.search;
      })
      .addCase(setCloudResourceFilter, (state, action) => {
        if (action.payload.filters?.values)
          state.cloudResourceFilter.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.cloudResourceFilter.search = action.payload.filters?.search;
      })
      .addCase("application/logout", (state) => {
        state.devices = {};
        state.awsResources = [];
        state.azureResources = [];
        state.cloudResourceFilter = {
          search: "",
          values: [],
        };
        state.cloudResources = {
          value: [],
          isLoading: false,
        };
        state.complianceStatus = [];
        state.deviceFilter = {};
        state.employee = {
          isLoading: false,
          value: {},
          employeeOffBoardIsLoading: false,
        };
        state.employeeControls = {
          employeeControls: {},
          isLoading: false,
        };
        state.employees = {
          isLoading: false,
          value: {},
          filters: {},
        };
        state.filteredData = [];
        state.resourceControls = {
          isLoading: false,
          value: [],
        };
        state.resourceControls = {
          isLoading: false,
          value: [],
        };
        state.searchFilterObject = {};
        state.selectedAccount = {};
        state.selectedDevice = {} as Resource;
        state.selectedResource = {} as Resource;
        state.updateCloudAccountClassificationData = {};
        state.updateCloudAccountOwnerData = {};
      })
      .addCase(getEmployees.pending, (state) => {
        state.employees.isLoading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.employees.value = action.payload;
        state.employees.isLoading = false;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.employees.value.employees = [];
        state.employees.isLoading = false;
      })

      .addCase(editEmployee.pending, (state) => {
        state.editEmployeeIsLoading = true;
      })
      .addCase(editEmployee.fulfilled, (state) => {
        state.editEmployeeIsLoading = false;
      })
      .addCase(editEmployee.rejected, (state) => {
        state.editEmployeeIsLoading = false;
      })

      .addCase(getEmployeeControlsById.pending, (state) => {
        state.employeeControls.isLoading = true;
      })
      .addCase(getEmployeeControlsById.fulfilled, (state, action) => {
        state.employeeControls.employeeControls = action.payload;
        state.employeeControls.isLoading = false;
      })
      .addCase(getEmployeeControlsById.rejected, (state) => {
        state.employeeControls.isLoading = false;
      })

      .addCase(getEmployeeById.pending, (state) => {
        state.employee.isLoading = true;
      })
      .addCase(getEmployeeById.fulfilled, (state, action) => {
        state.employee.value = action.payload;
        state.employee.isLoading = false;
      })
      .addCase(getEmployeeById.rejected, (state) => {
        state.employee.isLoading = false;
      })
      .addCase(patchEmployeesOffBoard.pending, (state) => {
        state.employee.employeeOffBoardIsLoading = true;
      })
      .addCase(patchEmployeesOffBoard.fulfilled, (state) => {
        state.employee.employeeOffBoardIsLoading = false;
      })
      .addCase(patchEmployeesOffBoard.rejected, (state) => {
        state.employee.employeeOffBoardIsLoading = false;
      })

      .addCase(setEmployeeFilter, (state, action) => {
        if (action.payload.filters?.values)
          state.employees.filters.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.employees.filters.search = action.payload.filters?.search;
      })
      .addCase(getCloudResources.pending, (state) => {
        state.cloudResources.isLoading = true;
      })
      .addCase(getCloudResources.fulfilled, (state, action) => {
        state.cloudResources.value = action.payload;
        state.cloudResources.isLoading = false;
      })
      .addCase(getCloudResources.rejected, (state) => {
        state.cloudResources.value = [];
        state.cloudResources.isLoading = false;
      });
  },
});

export default resourcesSlice.reducer;

export const selectDevice = (state: RootState, deviceUUID: string) =>
  state.resources.devices.devices?.find((device) => device.id === +deviceUUID);

export const selectCloudAssetsWithFilter = (state: RootState) => {
  cloudFilter.options[2].selectData = [
    { label: "All" },
    ...new Set(
      state.users.usersFilter.map((user) => ({
        label: user.email || user.name || "asd",
        id: user.id,
      }))
    ),
  ];
  const filteredControls = state.cloudAssets.cloudAssetsData.cloud_assets;
  return filteredControls;
};

export const selectDevicesLoadingState = (state: RootState) => state.resources.devicesIsLoading;

export const selectResourceControls = (state: RootState) => ({
  controls: state.resources.resourceControls.value,
  isLoading: state.resources.resourceControls.isLoading,
});

export const selectResourceControl = (state: RootState, controlUUID: string) =>
  state.resources.resourceControls.value.find((control) => control.UUID === controlUUID);

export const selectAWSResourcesLoadingState = (state: RootState) =>
  state.resources.awsResourcesIsLoading;

export const selectAzureResourcesLoadingState = (state: RootState) =>
  state.resources.azureResourcesIsLoading;

export const selectSearchFilterObject = (state: RootState) => state.resources.searchFilterObject;

export const selectSelectedResource = (state: RootState) => state.resources.selectedResource;

export const selectedSelectedDevice = (state: RootState) => state.resources.selectedDevice;

export const selectUpdateResourceExemptIsLoading = (state: RootState) =>
  state.resources.resourceExemptStatusIsLoading;

export const selectComplianceStatus = (state: RootState) => state.resources.complianceStatus;

export const selectUpdateResourceOwnerIsLoading = (state: RootState) =>
  state.resources.updateResourceOwnerIsLoading;

export const selectUpdateResourcesIsLoading = (state: RootState) =>
  state.resources.updateResourceClassificationIsLoading;

export const selectAvailableAwsOwners = (state: RootState) =>
  state.resources.awsResources
    .filter((data) => data.OwnerName !== "")
    .flatMap((resource) => resource.OwnerName);

export const selectAvailableAzureOwners = (state: RootState) =>
  state.resources.azureResources
    .filter((data) => data.OwnerName !== "")
    .flatMap((resource) => resource.OwnerName);

export const selectAvailableDeviceOwners = (state: RootState) =>
  state.resources.devices?.devices
    ?.filter((data) => data.device_owner?.name !== "")
    .flatMap((device) => device.device_owner?.name);

export const selectAvailableDeviceUsers = (state: RootState) =>
  state.resources.devices?.devices
    ?.filter((data) => data.device_owner?.name !== "")
    .flatMap((device) => device.device_owner?.name);

export const selectAvailableAwsSubTypes = (state: RootState) =>
  state.resources.awsResources.flatMap((resource) => resource.SubType);

export const selecteAvailableAzureSubTypes = (state: RootState) =>
  state.resources.azureResources.flatMap((resource) => resource.SubType);

export const selectNonCompliantResourceCount = (state: RootState) => ({
  AWS: state.resources.cloudResources.value.filter(
    (data) => data.DataClassification === 2 && !data.Compliant
  ).length,
  GitHub: 0,
  Azure: 0,
});

export const selectNonCompliantControlCount = (state: RootState) =>
  state.resources.resourceControls.value.filter((control) => !control.Compliant && !control.Exempt)
    .length;

export const selectUpdateCloudAccountsIsLoading = (state: RootState) =>
  state.resources.updateCloudAccountsIsLoading;

export const selectUpdateCloudAccountOwnerIsLoading = (state: RootState) =>
  state.resources.updateCloudAccountOwnerIsLoading;

export const selectSelectedCloudAccount = (state: RootState) => state.resources.selectedAccount;

export const selectCloudAccountClassification = (state: RootState) =>
  state.resources.updateCloudAccountClassificationData;

export const selectCloudAccountOwner = (state: RootState) =>
  state.resources.updateCloudAccountOwnerData;

export const selectDeviceSearch = (state: RootState) => state.resources.deviceFilter.search || "";
export const selectCloudSearch = (state: RootState) =>
  state.resources.cloudResourceFilter.search || "";

export const selectEmployees = (state: RootState) => {
  const employees = state.resources.employees.value;

  return {
    employees,
    isLoading: state.resources.employees.isLoading,
  };
};

export const selectEmployee = (state: RootState, employeeUUID: number) =>
  state.resources.employees.value.employees?.find((el) => el.id === employeeUUID);

export const selectEmployeeControl = (state: RootState) => {
  const { employeeControls } = state.resources;
  return employeeControls;
};
export const selectEmployeeOffBoardIsLoading = (state: RootState) => ({
  employeesOffBoardIsLoading: state.resources.employee.employeeOffBoardIsLoading,
});
export const selectedResourceIsDeleting = (state: RootState) =>
  state.resources.deleteResourcesIsLoading;

export const selectEditEmployeeIsLoading = (state: RootState) =>
  state.resources.editEmployeeIsLoading;

export const selectCloudResources = (state: RootState) => ({
  value: state.resources.cloudResources.value,
  isLoading: state.resources.cloudResources.isLoading,
});

export const selectCloudResource = (state: RootState, resourceUUID: string) =>
  state.resources.cloudResources.value.find((el) => el.UUID === resourceUUID);
