import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  Configuration,
  ControlsControlIdPoliciesGet200Response,
  ExportedTasks,
  FailedResponse,
  PolicyApi,
  PolicyWithTaskRelationTaskIdGet200Response,
  TaskOwner,
  TasksApi,
  TasksLinkPolicyPutRequest,
  TasksIdAssetsGet200Response,
} from "mycio-openapi";
import isEqual from "lodash/isEqual";
import type { RootState } from "store";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import {
  taskCreateFailureMessage,
  taskCreateSuccessMessage,
  taskUpdateFailureMessage,
  taskUpdateSuccessMessage,
} from "utils/toasts/messages";
import { FrontendTask } from "utils/data/interfaces";
import axios from "axios";
import { getUsers } from "./users";

export interface TaskOwnerConfig extends TaskOwner {
  Avatar?: string;
}
interface PageProps {
  id?: number;
  page?: number;
  limit?: number;
}

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async (
    args: {
      task: FrontendTask;
      updatedAt: ReturnType<typeof Date.prototype.toISOString>;
    },
    thunkAPI
  ) => {
    const { task } = args;
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      if (task.id) {
        await api.tasksTaskIdPatch(task.id, task);
        showSuccessToast(taskUpdateSuccessMessage(task.name));
      }
    } catch {
      showErrorToast(taskUpdateFailureMessage);
    }
  }
);

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async (
    args: {
      task: FrontendTask;
      updatedAt: ReturnType<typeof Date.prototype.toISOString>;
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    const { task } = args;
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    try {
      const response = (await api.tasksPost(task)).data;
      showSuccessToast(taskCreateSuccessMessage(task.name));
      return { ...task, id: response.id };
    } catch {
      showErrorToast(taskCreateFailureMessage);
      return rejectWithValue("Failed to load");
    }
  }
);

export const refreshTaskDetails = createAsyncThunk(
  "tasks/refreshTaskDetails",
  async (uuid: number, { getState, rejectWithValue }) => {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

    try {
      const response = await api.tasksTaskIdCheckPost(uuid);
      showSuccessToast(taskUpdateSuccessMessage(response.data.name));
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getTaskById = createAsyncThunk("tasks/getTaskById", async (uuid: number, thunkAPI) => {
  const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
  const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

  try {
    const response = (await api.tasksTaskIdGet(uuid)).data;
    return response;
  } catch {
    return thunkAPI.rejectWithValue("Failed to load");
  }
});

export const updateTaskOwner = createAsyncThunk(
  "tasks/updateTaskOwner",
  async ({ uuid, ownerId }: { uuid: number; ownerId: number }, thunkAPI) => {
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);

    try {
      const task = await api.tasksTaskIdOwnerPatch(uuid, { owner_id: ownerId });
      showSuccessToast(taskUpdateSuccessMessage(task.data.name));
    } catch {
      showErrorToast(taskUpdateFailureMessage);
    }
  }
);

export const resetSelectedTask = createAction("tasks/resetSelectedTask");

export const getTasks = createAsyncThunk("tasks/getTasks", async (args: PageProps, thunkAPI) => {
  const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
  const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
  const response = (await api.tasksGet(args.page, args.limit)).data;
  let tasks: FrontendTask[] = [];

  response?.task_list?.forEach((task) => {
    const tempTask = { ...task };
    if (tempTask.due_date === "0001-01-01T00:00:00Z") delete tempTask.due_date;
    tasks.push({
      ...tempTask,
      status: tempTask.status,
      owners: tempTask.owners,
    });
  });

  tasks = tasks.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );
  return { tasks, total: response.total };
});

export const getTaskEvidenceByAssets = createAsyncThunk<TasksIdAssetsGet200Response, PageProps>(
  "tasks/getTaskEvidenceByAssets",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.tasksIdAssetsGet(arg.id as number, arg?.page, arg?.limit)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getTaskLinkedPoliciesById = createAsyncThunk<
  ControlsControlIdPoliciesGet200Response,
  PageProps
>("tasks/getTaskLinkedPoliciesById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.tasksTaskIdPoliciesGet(arg.id as number, arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getTaskAllPoliciesById = createAsyncThunk<
  ControlsControlIdPoliciesGet200Response,
  PageProps
>("tasks/getTaskAllPoliciesById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new PolicyApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (
      await api.policyWithTaskRelationTaskIdGet(arg.id as number, arg.page, arg.limit)
    ).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const updateTaskPoliciesById = createAsyncThunk(
  "tasks/updateTaskPoliciesById",
  async (arg: TasksLinkPolicyPutRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (
        await api.tasksLinkPolicyPut({
          task_id: arg.task_id,
          to_be_linked: arg.to_be_linked,
          to_be_unlinked: arg.to_be_unlinked,
        })
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getTaskCsvRoutee = createAsyncThunk<ExportedTasks>(
  "tasks/getTaskCsvRoutee",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TasksApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = (await api.tasksExportPost()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    value: [] as FrontendTask[],
    total: undefined as number | undefined,
    loading: false,
    selectedTask: {} as FrontendTask | undefined,
    loadingSelectedTask: false,
    loadingTaskCheck: false,
    TaskEvidenceByAssets: {} as TasksIdAssetsGet200Response,
    TaskEvidenceByAssetsLoading: false,
    TaskLinkedPoliciesById: {} as ControlsControlIdPoliciesGet200Response,
    TaskLinkedPoliciesByIdLoading: false,
    TaskAllPoliciesById: {} as PolicyWithTaskRelationTaskIdGet200Response,
    TaskAllPoliciesByIdLoading: false,
    TaskUpdatePoliciesByIdLoading: false,
    TaskCsvRoute: {} as ExportedTasks,
    TaskCsvRouteLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.value = action.payload.tasks;
        state.total = action.payload.total;
        state.loading = false;
      })
      .addCase(getTasks.rejected, (state) => {
        state.loading = false;
      })

      .addCase(refreshTaskDetails.pending, (state) => {
        state.loadingTaskCheck = true;
      })
      .addCase(refreshTaskDetails.fulfilled, (state, action) => {
        state.selectedTask = action.payload;
        state.loadingTaskCheck = false;
      })
      .addCase(refreshTaskDetails.rejected, (state) => {
        state.loadingTaskCheck = false;
      })

      .addCase(getTaskById.pending, (state) => {
        state.loadingSelectedTask = true;
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        state.selectedTask = action.payload;
        state.loadingSelectedTask = false;
      })
      .addCase(getTaskById.rejected, (state) => {
        state.loadingSelectedTask = false;
      })

      .addCase(resetSelectedTask, (state) => {
        state.selectedTask = undefined;
      })

      .addCase(updateTask.pending, (state, action) => {
        const { task } = action.meta.arg;
        // TODO: We need a better way to compare records...
        const index = state.value.findIndex((x) => x.id === task.id);
        if (index >= 0) {
          state.value[index] = {
            ...task,
          };
        }
      })
      .addCase(updateTask.rejected, (state, action) => {
        // TODO: Looks like there should be an alert shown if we get here...
        const { task } = action.meta.arg;
        const index = state.value.findIndex((x) => x.name === task.name);
        if (index >= 0) {
          state.value[index] = task;
        }
      })

      .addCase(createTask.pending, (state, action) => {
        const { task } = action.meta.arg;
        state.value.push(task);
      })
      .addCase(createTask.rejected, (state, action) => {
        const { task } = action.meta.arg;
        state.value.splice(
          state.value.findIndex((x) => isEqual(x, task)),
          1
        ); // Could also pop but might get some weird race condition
      })
      .addCase(createTask.fulfilled, (state, action) => {
        const { task } = action.meta.arg;
        if (action.payload)
          state.value[state.value.findIndex((x) => isEqual(x, task))] =
            action.payload as FrontendTask;
      })

      .addCase(getUsers.fulfilled, (state, action) => {
        const temp: FrontendTask[] = [];
        state.value.forEach((task) => {
          const tempOwners: TaskOwnerConfig[] = [];
          task.Owners?.forEach((owner) => {
            const foundUser = action.payload.users?.find((user) => user.id === owner.user_id);
            if (foundUser) {
              tempOwners.push({ ...owner, Avatar: foundUser.avatar });
            }
          });
          temp.push({ ...task, Owners: tempOwners });
        });
        state.value = temp;
      })

      .addCase("application/logout", (state) => {
        state.value = [];
        state.selectedTask = {} as FrontendTask | undefined;
      })

      .addCase(getTaskEvidenceByAssets.pending, (state) => {
        state.TaskEvidenceByAssetsLoading = true;
      })
      .addCase(getTaskEvidenceByAssets.fulfilled, (state, action) => {
        state.TaskEvidenceByAssets = action.payload;
        state.TaskEvidenceByAssetsLoading = false;
      })
      .addCase(getTaskEvidenceByAssets.rejected, (state) => {
        state.TaskEvidenceByAssetsLoading = false;
      })

      .addCase(getTaskLinkedPoliciesById.pending, (state) => {
        state.TaskLinkedPoliciesByIdLoading = true;
      })
      .addCase(getTaskLinkedPoliciesById.fulfilled, (state, action) => {
        state.TaskLinkedPoliciesById = action.payload;
        state.TaskLinkedPoliciesByIdLoading = false;
      })
      .addCase(getTaskLinkedPoliciesById.rejected, (state) => {
        state.TaskLinkedPoliciesByIdLoading = false;
      })

      .addCase(getTaskAllPoliciesById.pending, (state) => {
        state.TaskAllPoliciesByIdLoading = true;
      })
      .addCase(getTaskAllPoliciesById.fulfilled, (state, action) => {
        state.TaskAllPoliciesById = action.payload;
        state.TaskAllPoliciesByIdLoading = false;
      })
      .addCase(getTaskAllPoliciesById.rejected, (state) => {
        state.TaskAllPoliciesByIdLoading = false;
      })

      .addCase(updateTaskPoliciesById.pending, (state) => {
        state.TaskUpdatePoliciesByIdLoading = true;
      })
      .addCase(updateTaskPoliciesById.fulfilled, (state) => {
        state.TaskUpdatePoliciesByIdLoading = false;
      })
      .addCase(updateTaskPoliciesById.rejected, (state) => {
        state.TaskUpdatePoliciesByIdLoading = false;
      })

      .addCase(getTaskCsvRoutee.pending, (state) => {
        state.TaskCsvRouteLoading = true;
      })
      .addCase(getTaskCsvRoutee.fulfilled, (state, action) => {
        state.TaskCsvRoute = action.payload;
        state.TaskCsvRouteLoading = false;
      })
      .addCase(getTaskCsvRoutee.rejected, (state) => {
        state.TaskCsvRouteLoading = false;
      });
  },
});
export default tasksSlice.reducer;

export const getAllTasks = (state: RootState) => ({
  total: state.tasks.total,
  tasks: state.tasks.value,
  loading: state.tasks.loading,
});
