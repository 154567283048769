import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  Configuration,
  DataRiskCriteria,
  FailedResponse,
  RiskApi,
  RiskCategory,
  RiskCriteria,
  RiskCatalogueGet200Response,
  RiskDataType,
  RiskPostRequestInner,
  RiskAddedGet200Response,
} from "mycio-openapi";
import type { RootState } from "store";
import { showErrorToast, showSuccessToast } from "utils/toasts";

interface PaginationPayload {
  page?: number;
  limit?: number;
}

export const riskCriteriaCheck = createAsyncThunk(
  "risk/riskCriteriaCheck",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskRiskCriteriaCheckGet();
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getRiskCriteria = createAsyncThunk(
  "risk/getRiskCriteria",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskRiskCriteriaGet();
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateRiskCriteria = createAsyncThunk(
  "risk/updateRiskCriteria",
  async (args: { payload: RiskCriteria }, { rejectWithValue, getState }) => {
    try {
      const { payload } = args;
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskRiskCriteriaPut(payload);
      const { data } = response;
      showSuccessToast("Risk Criteria updated successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRiskCategories = createAsyncThunk(
  "risk/getRiskCategories",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskCategoriesGet();
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRiskCatalogue = createAsyncThunk<RiskCatalogueGet200Response, PaginationPayload>(
  "risk/getRiskCatalogue",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskCatalogueGet(arg.page, arg.limit);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getDataRiskCriteria = createAsyncThunk(
  "risk/getDataRiskCriteria",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskDataRiskCriteriaGet();
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateDataRiskCriteria = createAsyncThunk(
  "risk/updateDataRiskCriteria",
  async (args: DataRiskCriteria[], { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskDataRiskCriteriaPut(args);
      const { data } = response;
      showSuccessToast("Risk Criteria updated successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addRisk = createAsyncThunk<string, RiskPostRequestInner[]>(
  "risk/addRisk",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskPost(arg);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAddedRisks = createAsyncThunk<RiskAddedGet200Response, PaginationPayload>(
  "risk/getAddedRisks",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new RiskApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
      const response = await api.riskAddedGet(arg.page, arg.limit);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const risksSlice = createSlice({
  name: "risks",
  initialState: {
    isRiskEstablished: false,
    isRiskEstablishedLoading: false,
    riskCriteria: {} as RiskCriteria,
    riskCriteriaIsLoading: false,
    riskCategories: [] as RiskCategory[],
    isRiskCategoriesLoading: false,
    risksCatalogue: {} as RiskCatalogueGet200Response,
    isRisksCatalogueLoading: false,
    RiskCriteriaData: [] as RiskDataType[],
    RiskCriteriaDataLoading: false,
    RiskCriteriaDataUpdateLoading: false,
    isAddRiskLoading: false,
    addedRisks: {} as RiskAddedGet200Response,
    isAddedRisksLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(riskCriteriaCheck.pending, (state) => {
        state.isRiskEstablishedLoading = true;
      })
      .addCase(riskCriteriaCheck.fulfilled, (state, action) => {
        state.isRiskEstablished = action.payload.exists ?? false;
        state.isRiskEstablishedLoading = false;
      })
      .addCase(riskCriteriaCheck.rejected, (state) => {
        state.isRiskEstablishedLoading = false;
      })
      .addCase(getRiskCriteria.pending, (state) => {
        state.riskCriteriaIsLoading = true;
      })
      .addCase(getRiskCriteria.fulfilled, (state, action) => {
        state.riskCriteria = action.payload;
        state.riskCriteriaIsLoading = false;
      })
      .addCase(getRiskCriteria.rejected, (state) => {
        state.riskCriteriaIsLoading = false;
      })
      .addCase(updateRiskCriteria.pending, (state) => {
        state.riskCriteriaIsLoading = true;
      })
      .addCase(updateRiskCriteria.fulfilled, (state) => {
        state.riskCriteriaIsLoading = false;
      })
      .addCase(updateRiskCriteria.rejected, (state) => {
        state.riskCriteriaIsLoading = false;
      })
      .addCase(getRiskCategories.pending, (state) => {
        state.isRiskCategoriesLoading = true;
      })
      .addCase(getRiskCategories.fulfilled, (state, action) => {
        state.riskCategories = action.payload;
        state.isRiskCategoriesLoading = false;
      })
      .addCase(getRiskCategories.rejected, (state) => {
        state.isRiskCategoriesLoading = false;
      })

      .addCase(getRiskCatalogue.pending, (state) => {
        state.isRisksCatalogueLoading = true;
      })
      .addCase(getRiskCatalogue.fulfilled, (state, action) => {
        state.risksCatalogue = action.payload;
        state.isRisksCatalogueLoading = false;
      })
      .addCase(getRiskCatalogue.rejected, (state) => {
        state.isRisksCatalogueLoading = false;
      })

      .addCase(getDataRiskCriteria.pending, (state) => {
        state.RiskCriteriaDataLoading = true;
      })
      .addCase(getDataRiskCriteria.fulfilled, (state, action) => {
        state.RiskCriteriaData = action.payload;
        state.RiskCriteriaDataLoading = false;
      })
      .addCase(getDataRiskCriteria.rejected, (state) => {
        state.RiskCriteriaDataLoading = false;
      })

      .addCase(updateDataRiskCriteria.pending, (state) => {
        state.RiskCriteriaDataLoading = true;
      })
      .addCase(updateDataRiskCriteria.fulfilled, (state) => {
        state.RiskCriteriaDataLoading = false;
      })
      .addCase(updateDataRiskCriteria.rejected, (state) => {
        state.RiskCriteriaDataLoading = false;
      })
      .addCase(addRisk.pending, (state) => {
        state.isAddRiskLoading = true;
      })
      .addCase(addRisk.fulfilled, (state) => {
        state.isAddRiskLoading = false;
      })
      .addCase(addRisk.rejected, (state) => {
        state.isAddRiskLoading = false;
      })
      .addCase(getAddedRisks.pending, (state) => {
        state.isAddedRisksLoading = true;
      })
      .addCase(getAddedRisks.fulfilled, (state, action) => {
        state.addedRisks = action.payload;
        state.isAddedRisksLoading = false;
      })
      .addCase(getAddedRisks.rejected, (state) => {
        state.isAddedRisksLoading = false;
      });
  },
});
export default risksSlice.reducer;
