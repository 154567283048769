import { IconProps } from "components/icons/Icon.interface";

export default function MS365({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Microsoft"
      role="img"
      viewBox="0 0 512 512"
      className={className}
    >
      <rect width="512" height="512" rx="15%" fill="#fff" />
      <path d="M75 75v171h171v-171z" fill="#f25022" />
      <path d="M266 75v171h171v-171z" fill="#7fba00" />
      <path d="M75 266v171h171v-171z" fill="#00a4ef" />
      <path d="M266 266v171h171v-171z" fill="#ffb900" />
    </svg>
  );
}
