import type { RootState } from "store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  Configuration,
  FailedResponse,
  GetCloudAssetsResponse,
  GetConnectedIntegrationsResponse,
  IntegrationsApi,
  CloudAssetApi,
  Asset,
  AssetsIdControlsGet200Response,
  SkeldusControlMini,
  AccountsIdOwnerPatchRequest,
  AssetsIdDataClassificationPatchRequest,
  IntegrationsCloudAssetsIdGetClassificationLevelEnum,
  IntegrationsCloudAssetsIdGetSearchByEnum,
  TenantApi,
  AssetTypeShort,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";
import { cloudFilter } from "components/FilterSidebar/FilterOptions";

interface PaginationPayload {
  id: number;
  searchBy?: IntegrationsCloudAssetsIdGetSearchByEnum;
  searchKeyword?: string;
  ownerId?: number;
  classificationLevel?: Array<IntegrationsCloudAssetsIdGetClassificationLevelEnum>;
  compliant?: boolean;
  page?: number;
  limit?: number;
}

interface AssetControlsType {
  non_compliant_control_total?: number;
  total?: number;
  controls?: SkeldusControlMini[];
}

export const getCloudAccounts = createAsyncThunk<GetConnectedIntegrationsResponse>(
  "cloudAssets/getCloudAccounts",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new IntegrationsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.integrationsConnectedGet()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCloudAccountsById = createAsyncThunk<
  GetCloudAssetsResponse,
  PaginationPayload | undefined
>("cloudAssets/getCloudAccountsById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new IntegrationsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (
      await api.integrationsCloudAssetsIdGet(
        arg?.id as number,
        arg?.searchBy,
        arg?.searchKeyword,
        arg?.ownerId,
        arg?.classificationLevel,
        arg?.compliant,
        arg?.page,
        arg?.limit
      )
    ).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getCloudAssetById = createAsyncThunk<Asset, { id: number }>(
  "cloudAssets/getCloudAssetById",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new CloudAssetApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.assetsIdGet(arg.id)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getControlByIdAssets = createAsyncThunk<
  AssetsIdControlsGet200Response,
  { id: number; page?: number; limit?: number }
>("cloudAssets/getControlByIdAssets", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new CloudAssetApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.assetsIdControlsGet(arg.id, arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const changeCloudAssetOwner = createAsyncThunk<
  void,
  { id: number; accountsIdOwnerPatchRequest?: AccountsIdOwnerPatchRequest }
>("cloudAssets/changeCloudAssetOwner", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new CloudAssetApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.assetsIdOwnerPatch(arg.id, arg.accountsIdOwnerPatchRequest)).data;
    showSuccessToast("Owner changed successfully");
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const updateDataClassification = createAsyncThunk<
  void,
  { id: number; assetsIdDataClassificationPatchRequest?: AssetsIdDataClassificationPatchRequest }
>("cloudAssets/patchAssetsIdDataClassification", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new CloudAssetApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    await api.assetsIdDataClassificationPatch(arg.id, arg.assetsIdDataClassificationPatchRequest);
    showSuccessToast("Data Classification changed successfully");
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getAssetTypes = createAsyncThunk(
  "cloudAssets/getAssetTypes",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new TenantApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.tenantAssetTypesGet()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const cloudAssetsSlice = createSlice({
  name: "cloudAssets",
  initialState: {
    cloudAssetsList: {} as GetConnectedIntegrationsResponse,
    cloudAssetsIsLoading: false,
    cloudAssetsData: {} as GetCloudAssetsResponse,
    cloudAssetsDataIsLoading: false,
    cloudAssetTab: undefined as string | undefined,
    cloudAssetById: {} as Asset,
    cloudAssetByIdIsLoading: false,
    cloudAssetChangeOwnerIsLoading: false,
    isAssetsControlLoading: false,
    assetControls: {} as AssetControlsType,
    changeDataClassificationLoading: false,
    assetTypes: [] as AssetTypeShort[],
    assetTypesIsLoading: false,
  },
  reducers: {
    setCloudAssetTab: (state, action: PayloadAction<string | undefined>) => {
      state.cloudAssetTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCloudAccounts.pending, (state) => {
        state.cloudAssetsIsLoading = true;
      })
      .addCase(getCloudAccounts.fulfilled, (state, action) => {
        state.cloudAssetsList = action.payload;
        state.cloudAssetsIsLoading = false;
      })
      .addCase(getCloudAccounts.rejected, (state) => {
        state.cloudAssetsIsLoading = false;
      })

      .addCase(getCloudAccountsById.pending, (state) => {
        state.cloudAssetsDataIsLoading = true;
      })
      .addCase(getCloudAccountsById.fulfilled, (state, action) => {
        state.cloudAssetsData = action.payload;
        state.cloudAssetsDataIsLoading = false;
      })
      .addCase(getCloudAccountsById.rejected, (state) => {
        state.cloudAssetsDataIsLoading = false;
      })

      .addCase(getCloudAssetById.pending, (state) => {
        state.cloudAssetByIdIsLoading = true;
      })
      .addCase(getCloudAssetById.fulfilled, (state, action) => {
        state.cloudAssetById = action.payload;
        state.cloudAssetByIdIsLoading = false;
      })
      .addCase(getCloudAssetById.rejected, (state) => {
        state.cloudAssetByIdIsLoading = false;
      })

      .addCase(getControlByIdAssets.pending, (state) => {
        state.isAssetsControlLoading = true;
      })
      .addCase(getControlByIdAssets.fulfilled, (state, action) => {
        state.assetControls = action.payload;
        state.isAssetsControlLoading = false;
      })
      .addCase(getControlByIdAssets.rejected, (state) => {
        state.isAssetsControlLoading = false;
      })

      .addCase(changeCloudAssetOwner.pending, (state) => {
        state.cloudAssetChangeOwnerIsLoading = true;
      })
      .addCase(changeCloudAssetOwner.fulfilled, (state) => {
        state.cloudAssetChangeOwnerIsLoading = false;
      })
      .addCase(changeCloudAssetOwner.rejected, (state) => {
        state.cloudAssetChangeOwnerIsLoading = false;
      })

      .addCase(updateDataClassification.pending, (state) => {
        state.changeDataClassificationLoading = true;
      })
      .addCase(updateDataClassification.fulfilled, (state) => {
        state.changeDataClassificationLoading = false;
      })
      .addCase(updateDataClassification.rejected, (state) => {
        state.changeDataClassificationLoading = false;
      })

      .addCase(getAssetTypes.pending, (state) => {
        state.assetTypesIsLoading = true;
      })
      .addCase(getAssetTypes.fulfilled, (state, action) => {
        state.assetTypes = action.payload;
        state.assetTypesIsLoading = false;
      })
      .addCase(getAssetTypes.rejected, (state) => {
        state.assetTypesIsLoading = false;
      })

      .addCase("application/logout", (state) => {
        state.cloudAssetsData = {};
        state.cloudAssetsList = {};
      });
  },
});

export const selectCloudAssetById = (state: RootState) => {
  cloudFilter.options[2].selectData = [
    { id: 0, label: "All" },
    ...new Set(
      state.users.users.users?.map((user) => ({
        label: user.email || user.name || "",
        id: user.id,
      }))
    ),
  ];
  const cloudAsset = state.cloudAssets.cloudAssetById;
  const cloudAssetsLoading = state.cloudAssets.cloudAssetByIdIsLoading;
  return {
    cloudAsset,
    cloudAssetsLoading,
  };
};

export const selectCloudAssetDataById = (state: RootState) => {
  const { cloudAssetsData } = state.cloudAssets;
  const cloudAssetDataIsLoading = state.cloudAssets.cloudAssetsDataIsLoading;
  return {
    cloudAssetsData,
    cloudAssetDataIsLoading,
  };
};
export const selectCloudAssetControls = (state: RootState) => {
  const controls = state.cloudAssets.assetControls;
  const controlsIsLoading = state.cloudAssets.isAssetsControlLoading;

  return {
    controls,
    controlsIsLoading,
  };
};
export const selectChangeCloudAssetOwnerIsLOading = (state: RootState) =>
  state.cloudAssets.cloudAssetChangeOwnerIsLoading;

export const { setCloudAssetTab } = cloudAssetsSlice.actions;

export default cloudAssetsSlice.reducer;
