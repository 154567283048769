import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import type { RootState } from "store";
import axios from "axios";
import {
  Configuration,
  VendorApi,
  FailedResponse,
  QuestionnaireApi,
  VendorQuestionnaireGet200Response,
  VendorQuestionnairePostRequest,
  VendorQuestionnaireSubmitPostRequest,
  SecurityReviewStatus,
  VendorIdSecurityReviewStatusHistoryGet200Response,
  SecurityReviewStatusResponse,
  AddAssessmentRequest,
} from "mycio-openapi";

interface PaginationPayload {
  id?: number;
  page?: number;
  limit?: number;
}

export const getQuestionnaire = createAsyncThunk<
  VendorQuestionnaireGet200Response,
  { token: string }
>("questionnaire/getQuestionnaire", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.vendorQuestionnaireGet(arg.token)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const submitQuestionnairePost = createAsyncThunk<
  void,
  { token: string; vendorQuestionnaireSubmitPostRequest: VendorQuestionnaireSubmitPostRequest }
>("questionnaire/submitQuestionnairePost", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (
      await api.vendorQuestionnaireSubmitPost(arg.token, arg.vendorQuestionnaireSubmitPostRequest)
    ).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const postVendorQuestionnaire = createAsyncThunk<void, VendorQuestionnairePostRequest>(
  "questionnaire/postVendorQuestionnaire",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new QuestionnaireApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.vendorQuestionnairePost(arg);
      showSuccessToast("Review Submitted Successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);

export const getVendorSecurityReviewStatusById = createAsyncThunk<
  SecurityReviewStatusResponse,
  number
>("vendors/getVendorSecurityReviewStatusById", async (id, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.vendorIdSecurityReviewStatusGet(id)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const cancelVendorQuestionnaire = createAsyncThunk<void, number>(
  "questionnaire/cancelVendorQuestionnaire",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.vendorQuestionnaireIdCancelPatch(id);
      showSuccessToast("Review Cancelled Successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);

export const resendVendorQuestionnaire = createAsyncThunk<void, number>(
  "questionnaire/resendVendorQuestionnaire",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.vendorQuestionnaireIdResendPatch(id);
      showSuccessToast("Review Resent Successfully");
      return;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      rejectWithValue("Something went wrong");
    }
  }
);

export const getVendorIdSecurityReviewStatusHistory = createAsyncThunk<
  VendorIdSecurityReviewStatusHistoryGet200Response,
  PaginationPayload | undefined
>("vendors/getVendorIdSecurityReviewStatusHistory", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (
      await api.vendorIdSecurityReviewStatusHistoryGet(arg?.id as number, arg?.page, arg?.limit)
    ).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const patchVendorQuestionnaireIdAssessment = createAsyncThunk<
  void,
  { id: number; addAssessmentRequest: AddAssessmentRequest }
>(
  "questionnaire/patchVendorQuestionnaireIdAssessment",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.vendorQuestionnaireIdAssessmentPatch(arg.id, arg.addAssessmentRequest)
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    questionnaire: {} as VendorQuestionnaireGet200Response,
    questionnaireIsLoading: false,
    submitQuestionnairePostIsLoading: false,
    postVendorQuestionnaireIsLoading: false,
    cancelVendorQuestionnaireIsLoading: false,
    resendVendorQuestionnaireIsLoading: false,
    vendorSecurityReviewStatus: {} as SecurityReviewStatusResponse,
    vendorSecurityReviewStatusLoading: false,
    VendorIdSecurityReviewStatusHistory: {} as VendorIdSecurityReviewStatusHistoryGet200Response,
    VendorIdSecurityReviewStatusHistoryLoading: false,
    patchVendorQuestionnaireIdAssessmentLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getQuestionnaire.pending, (state) => {
        state.questionnaireIsLoading = true;
      })
      .addCase(getQuestionnaire.fulfilled, (state, action) => {
        state.questionnaire = action.payload;
        state.questionnaireIsLoading = false;
      })
      .addCase(getQuestionnaire.rejected, (state) => {
        state.questionnaireIsLoading = false;
      })

      .addCase(submitQuestionnairePost.pending, (state) => {
        state.submitQuestionnairePostIsLoading = true;
      })
      .addCase(submitQuestionnairePost.fulfilled, (state) => {
        state.submitQuestionnairePostIsLoading = false;
      })
      .addCase(submitQuestionnairePost.rejected, (state) => {
        state.submitQuestionnairePostIsLoading = false;
      })

      .addCase(postVendorQuestionnaire.pending, (state) => {
        state.postVendorQuestionnaireIsLoading = true;
      })
      .addCase(postVendorQuestionnaire.fulfilled, (state) => {
        state.postVendorQuestionnaireIsLoading = false;
      })
      .addCase(postVendorQuestionnaire.rejected, (state) => {
        state.postVendorQuestionnaireIsLoading = false;
      })

      .addCase(getVendorSecurityReviewStatusById.pending, (state) => {
        state.vendorSecurityReviewStatusLoading = true;
      })
      .addCase(getVendorSecurityReviewStatusById.fulfilled, (state, action) => {
        state.vendorSecurityReviewStatus = action.payload;
        state.vendorSecurityReviewStatusLoading = false;
      })
      .addCase(getVendorSecurityReviewStatusById.rejected, (state) => {
        state.vendorSecurityReviewStatusLoading = false;
      })

      .addCase(cancelVendorQuestionnaire.pending, (state) => {
        state.cancelVendorQuestionnaireIsLoading = true;
      })
      .addCase(cancelVendorQuestionnaire.fulfilled, (state) => {
        state.cancelVendorQuestionnaireIsLoading = false;
      })
      .addCase(cancelVendorQuestionnaire.rejected, (state) => {
        state.cancelVendorQuestionnaireIsLoading = false;
      })

      .addCase(resendVendorQuestionnaire.pending, (state) => {
        state.resendVendorQuestionnaireIsLoading = true;
      })
      .addCase(resendVendorQuestionnaire.fulfilled, (state) => {
        state.resendVendorQuestionnaireIsLoading = false;
      })
      .addCase(resendVendorQuestionnaire.rejected, (state) => {
        state.resendVendorQuestionnaireIsLoading = false;
      })

      .addCase(getVendorIdSecurityReviewStatusHistory.pending, (state) => {
        state.VendorIdSecurityReviewStatusHistoryLoading = true;
      })
      .addCase(getVendorIdSecurityReviewStatusHistory.fulfilled, (state, action) => {
        state.VendorIdSecurityReviewStatusHistory = action.payload;
        state.VendorIdSecurityReviewStatusHistoryLoading = false;
      })
      .addCase(getVendorIdSecurityReviewStatusHistory.rejected, (state) => {
        state.VendorIdSecurityReviewStatusHistoryLoading = false;
      })

      .addCase(patchVendorQuestionnaireIdAssessment.pending, (state) => {
        state.VendorIdSecurityReviewStatusHistoryLoading = true;
      })
      .addCase(patchVendorQuestionnaireIdAssessment.fulfilled, (state) => {
        state.VendorIdSecurityReviewStatusHistoryLoading = false;
      })
      .addCase(patchVendorQuestionnaireIdAssessment.rejected, (state) => {
        state.VendorIdSecurityReviewStatusHistoryLoading = false;
      });
  },
});

export const selectVendorQuestionnaire = (state: RootState) => state.questionnaire;

export default questionnaireSlice.reducer;
