import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { accountFilter } from "components/FilterSidebar/FilterOptions";
import { isString } from "lodash";
import {
  Account,
  AccountsApi,
  AccountsGet200Response,
  AccountsIdControlsGet200Response,
  Configuration,
  FailedResponse,
} from "mycio-openapi";
import type { RootState } from "store";
import { showErrorToast } from "utils/toasts";

interface Filter {
  values?: string[];
  search?: string;
}
interface PaginationPayload {
  compliant?: boolean;
  employeeId?: number;
  page?: number;
  limit?: number;
  id?: number;
}
interface ControlAccountsPayload {
  id: number;
  page?: number;
  limit?: number;
}

export const getAccounts = createAsyncThunk<AccountsGet200Response, PaginationPayload>(
  "accounts/getAccounts",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new AccountsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.accountsGet(arg.compliant, arg.employeeId, arg.page, arg.limit);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getAccountById = createAsyncThunk<Account, number>(
  "accounts/getAccountById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new AccountsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.accountsIdGet(id);
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAccountControlById = createAsyncThunk<
  AccountsIdControlsGet200Response,
  ControlAccountsPayload
>("accounts/getAccountControlById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = await api.accountsIdControlsGet(arg.id, arg?.page, arg?.limit);
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong..");
    return rejectWithValue("Something went wrong");
  }
});

export const setAccountFilter = createAction("controls/setAccountFilter", (filters?: Filter) => ({
  payload: {
    filters,
  },
}));

export const accountsSlice = createSlice({
  name: "accounts",
  initialState: {
    accounts: {} as AccountsGet200Response,
    accountById: {} as Account,
    accoundByIdLoading: false,
    accountsLoading: false,
    filters: {
      values: [],
      search: "",
    } as Filter,
    controlByIdAccount: {} as AccountsIdControlsGet200Response,
    controlByIdAccountIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccounts.pending, (state) => {
        state.accountsLoading = true;
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload;
        state.accountsLoading = false;
      })
      .addCase(getAccounts.rejected, (state) => {
        state.accountsLoading = false;
      })
      .addCase(getAccountById.pending, (state) => {
        state.accoundByIdLoading = true;
      })
      .addCase(getAccountById.fulfilled, (state, { payload }) => {
        state.accountById = payload;
        state.accoundByIdLoading = false;
      })
      .addCase(getAccountById.rejected, (state) => {
        state.accoundByIdLoading = false;
      })
      .addCase(getAccountControlById.pending, (state) => {
        state.controlByIdAccountIsLoading = true;
      })
      .addCase(getAccountControlById.fulfilled, (state, action) => {
        state.controlByIdAccount = action.payload;
        state.controlByIdAccountIsLoading = false;
      })
      .addCase(getAccountControlById.rejected, (state) => {
        state.controlByIdAccountIsLoading = false;
      })

      .addCase(setAccountFilter, (state, action) => {
        if (action.payload.filters?.values) state.filters.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.filters.search = action.payload.filters?.search;
      })
      .addCase("application/logout", (state) => {
        state.accounts = {};
        state.accountById = {};
      });
  },
});
export default accountsSlice.reducer;

export const selectAccounts = (state: RootState) => {
  accountFilter.options[1].selectData = [
    { id: 0, label: "All" },
    ...new Set(
      state.resources.employees.value?.employees?.map((user) => ({
        label: user.email || user.first_name || "",
        id: user.id,
      }))
    ),
  ];
  const accounts = state.accounts?.accounts;
  return {
    accounts,
    isLoading: state.accounts?.accountsLoading,
  };
};
export const selectControlByAccount = (state: RootState) => {
  const controls = state.accounts.controlByIdAccount;
  const controlsIsLoading = state.accounts.controlByIdAccountIsLoading;

  return {
    controls,
    controlsIsLoading,
  };
};
export const selectAccountById = ({ accounts }: RootState) => {
  const { accountById } = accounts;

  return {
    accountById,
    isLoading: accounts.accoundByIdLoading,
  };
};
export const selectAccountSearch = (state: RootState) => state.accounts.filters.search || "";
