import type { RootState } from "store";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  Device,
  DevicesApi,
  DeviceRequest,
  DevicesResponse,
  Configuration,
  FailedResponse,
  AccountsIdControlsGet200Response,
  AccountsIdOwnerPatchRequest,
  DevicesGetSearchByEnum,
  DevicesGetClassificationLevelEnum,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";
import { isString } from "lodash";
import { deviceFilter } from "components/FilterSidebar/FilterOptions";

interface PaginationPayload {
  id?: number;
  page?: number;
  limit?: number;
  searchBy?: DevicesGetSearchByEnum;
  searchKeyword?: string;
  ownerId?: number;
  userId?: number;
  compliant?: boolean;
  classificationLevel?: Array<DevicesGetClassificationLevelEnum>;
}

interface Filter {
  values?: string[];
  search?: string;
}

export const getDevices = createAsyncThunk<DevicesResponse, PaginationPayload | undefined>(
  "devices/getDevices",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.devicesGet(
          arg?.page,
          arg?.limit,
          arg?.searchBy,
          arg?.searchKeyword,
          arg?.ownerId,
          arg?.userId,
          arg?.compliant,
          arg?.classificationLevel
        )
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getDevicesUsers = createAsyncThunk<DevicesResponse, PaginationPayload | undefined>(
  "devices/getDevicesUsers",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.devicesGet(
          arg?.page,
          arg?.limit,
          arg?.searchBy,
          arg?.searchKeyword,
          arg?.ownerId,
          arg?.userId,
          arg?.compliant,
          arg?.classificationLevel
        )
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getDeviceById = createAsyncThunk<Device, number>(
  "devices/getDeviceById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.devicesIdGet(id)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getDeviceControlsById = createAsyncThunk<
  AccountsIdControlsGet200Response,
  PaginationPayload
>("devices/getDeviceControlsById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DevicesApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.devicesIdControlsGet(arg?.id as number, arg?.page, arg?.limit))
      .data;

    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const addDevice = createAsyncThunk(
  "devices/addDevice",
  async (payload: DeviceRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.devicesPost(payload);
      const { data } = response;
      showSuccessToast("Device Added successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const changeDeviceOwner = createAsyncThunk(
  "devices/changeDeviceOwner",
  async (
    args: { id: number; accountsIdOwnerPatchRequest?: AccountsIdOwnerPatchRequest },
    { rejectWithValue, getState }
  ) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.devicesIdOwnerPatch(args.id, args.accountsIdOwnerPatchRequest);
      const { data } = response;
      showSuccessToast("Owner changed successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addDevices = createAsyncThunk(
  "devices/addDevices",
  async (args: File, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.devicesListPost(args);
      const { data } = response;
      showSuccessToast("Devices uploaded successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editDeviceById = createAsyncThunk<void, { id: number; deviceRequest: DeviceRequest }>(
  "devices/editDeviceById",
  async (args, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new DevicesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.devicesIdPatch(args.id, args.deviceRequest);
      const { data } = response;
      showSuccessToast("Devices upgraded successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const changeEmployeeDevice = createAsyncThunk<
  void,
  { id: number; deviceRequest?: DeviceRequest }
>("devices/changeEmployeeDevice", async (args, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new DevicesApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = await api.devicesIdPatch(args.id, args.deviceRequest);
    const { data } = response;
    showSuccessToast("Employee updated successfully");
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const setDeviceFilter = createAction("devices/setDeviceFilter", (filters?: Filter) => ({
  payload: {
    filters,
  },
}));

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    devices: {} as DevicesResponse,
    devicesUser: {} as DevicesResponse,
    deviceById: {} as Device,
    deviceControls: {} as AccountsIdControlsGet200Response,
    devicesUserLoading: false,
    devicesLoading: false,
    deviceByIdLoading: false,
    changeDeviceOwnerIsLoading: false,
    deviceControlsLoading: false,
    changeEmployeeDeviceIsLoading: false,
    deviceFilter: {
      search: "",
      values: [],
    } as Filter,
    editDeviceByIdIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDevices.pending, (state) => {
        state.devicesLoading = true;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.devices = action.payload;
        state.devicesLoading = false;
      })
      .addCase(getDevices.rejected, (state) => {
        state.devicesLoading = false;
      })
      .addCase(getDevicesUsers.pending, (state) => {
        state.devicesUserLoading = true;
      })
      .addCase(getDevicesUsers.fulfilled, (state, action) => {
        state.devicesUser = action.payload;
        state.devicesUserLoading = false;
      })
      .addCase(getDevicesUsers.rejected, (state) => {
        state.devicesUserLoading = false;
      })

      .addCase(getDeviceById.pending, (state) => {
        state.deviceByIdLoading = true;
      })
      .addCase(getDeviceById.fulfilled, (state, action) => {
        state.deviceById = action.payload;
        state.deviceByIdLoading = false;
      })
      .addCase(getDeviceById.rejected, (state) => {
        state.deviceByIdLoading = false;
      })

      .addCase(getDeviceControlsById.pending, (state) => {
        state.deviceControlsLoading = true;
      })
      .addCase(getDeviceControlsById.fulfilled, (state, action) => {
        state.deviceControls = action.payload;
        state.deviceControlsLoading = false;
      })
      .addCase(getDeviceControlsById.rejected, (state) => {
        state.deviceControlsLoading = false;
      })

      .addCase(addDevice.pending, (state) => {
        state.devicesLoading = true;
      })
      .addCase(addDevice.fulfilled, (state) => {
        state.devicesLoading = false;
      })
      .addCase(addDevice.rejected, (state) => {
        state.devicesLoading = false;
      })

      .addCase(addDevices.pending, (state) => {
        state.devicesLoading = true;
      })
      .addCase(addDevices.fulfilled, (state) => {
        state.devicesLoading = false;
      })
      .addCase(addDevices.rejected, (state) => {
        state.devicesLoading = false;
      })

      .addCase(changeDeviceOwner.pending, (state) => {
        state.changeDeviceOwnerIsLoading = true;
      })
      .addCase(changeDeviceOwner.fulfilled, (state) => {
        state.changeDeviceOwnerIsLoading = false;
      })
      .addCase(changeDeviceOwner.rejected, (state) => {
        state.changeDeviceOwnerIsLoading = false;
      })

      .addCase(editDeviceById.pending, (state) => {
        state.editDeviceByIdIsLoading = true;
      })
      .addCase(editDeviceById.fulfilled, (state) => {
        state.editDeviceByIdIsLoading = false;
      })
      .addCase(editDeviceById.rejected, (state) => {
        state.editDeviceByIdIsLoading = false;
      })

      .addCase(changeEmployeeDevice.pending, (state) => {
        state.changeEmployeeDeviceIsLoading = true;
      })
      .addCase(changeEmployeeDevice.fulfilled, (state) => {
        state.changeEmployeeDeviceIsLoading = false;
      })
      .addCase(changeEmployeeDevice.rejected, (state) => {
        state.changeEmployeeDeviceIsLoading = false;
      })

      .addCase(setDeviceFilter, (state, action) => {
        if (action.payload.filters?.values)
          state.deviceFilter.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.deviceFilter.search = action.payload.filters?.search;
      })
      .addCase("application/logout", (state) => {
        state.deviceControls = {};
        state.devices = {};
        state.deviceById = {};
        state.deviceFilter = {};
      });
  },
});

export default devicesSlice.reducer;

export const selectDevices = (state: RootState) => {
  deviceFilter.options[2].selectData = [
    { id: 0, label: "All" },
    ...new Set(
      state.users.users.users?.map((user) => ({
        label: user.email || user.name || "asd",
        id: user.id,
      }))
    ),
  ];
  deviceFilter.options[3].selectData = [
    { id: 0, label: "All" },
    ...new Set(
      state.devices.devicesUser.devices?.map((device) => ({
        label: device?.device_user?.email || device?.device_user?.first_name || "",
        id: device?.device_user?.id,
      }))
    ),
  ];
  const { devices } = state.devices;

  return {
    devices,
    isLoading: state.devices.devicesLoading,
  };
};

export const selectDevice = (state: RootState) => {
  const device = state.devices.deviceById;

  return {
    device,
    isLoading: state.devices.deviceByIdLoading,
  };
};

export const selectChangeEmployeeDeviceIsLoading = (state: RootState) =>
  state.devices.changeEmployeeDeviceIsLoading;
export const selectEditDeviceById = (state: RootState) => state.devices.editDeviceByIdIsLoading;

export const selectDeviceFilters = (state: RootState) => state.devices.deviceFilter.values || [];
