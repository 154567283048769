import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  Configuration,
  AccountsApi,
  AwsIntegrationConfig,
  CloudAccounts,
  CloudAccountAccountTypeEnum,
  CloudConfigApi,
  FailedResponse,
  IntegrationsApi,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";

import { Integration } from "utils/data/interfaces";
// TODO: static array temp fix to fix Dependency cycle error
// import { supportedIntegrationTypes } from "utils/data/integrations";

const currentEnabledTypes = ["AWS", "AZURE"];
const supportedIntegrationTypes = [
  "AWS",
  "AZUREAD",
  "AZURE",
  "GCP",
  "MICROSOFT365",
  "GWORKSPACE",
  "GWORKSPACE",
  "GITHUB",
  "SLACK",
  "CHECKMARX",
  "SNYK",
  "CLICKUP",
];

export const getIntegrationsList = createAsyncThunk(
  "integration/getIntegrationsList",
  async (_, { rejectWithValue, getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new IntegrationsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const response = (await api.integrationsGet()).data;

      const filteredResponse = response.filter(
        (integration) =>
          integration.integration_type &&
          supportedIntegrationTypes.includes(integration.integration_type)
      );

      const mappedResponse: Integration[] = filteredResponse.map((integration) => {
        if (
          integration.integration_type &&
          supportedIntegrationTypes.includes(integration.integration_type)
        )
          return {
            type: integration.integration_type || "",
            title: integration.integration_title || "",
            logo: integration.integration_image || "",
            description: integration.integration_description || "",
            integrations: integration.integrations || [],
            status: {
              value: integration.integration_status || "na",
              message: integration.integration_status || "",
            },
          };
        return {
          type: "",
          title: "",
          logo: "",
          description: "",
          integrations: [],
          status: { value: "na", message: "" },
        };
      });
      return mappedResponse;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

// getAWSIntegrationConfig Thunk
export const getAWSIntegrationConfig = createAsyncThunk(
  "integration/getAWSIntegrationConfig",
  async (_, { getState }) => {
    // const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    // const api = new IntegrationsAwsApi(
    //   { accessToken } as Configuration,
    //   process.env.NEXT_PUBLIC_MYCIO_API
    // );
    const response: AwsIntegrationConfig = {};
    // try {
    //   response = (await api.integrationsAwsGet()).data;
    // } catch (e) {
    //   response = {};
    // }
    return response;
  }
);

// getCloudAccounts Thunk
export const getCloudAccounts = createAsyncThunk(
  "integration/getCloudAccounts",
  async (_, { rejectWithValue, getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const response = await api.cloudAccountsGet();
      return response.data;
    } catch (e) {
      return rejectWithValue("Something went wrong..");
    }
  }
);

// connectCloudProviderIntegrationManual Thunk
export const connectCloudProviderIntegrationManual = createAsyncThunk(
  "integration/connectCloudProviderIntegrationManual",
  async (
    args: {
      payload: {
        SubscriptionID?: string;
        ClientSecret?: string;
        ClientID?: string;
        TenantID?: string;
        ARN?: string;
        AccountType: CloudAccountAccountTypeEnum;
      };
    },
    { rejectWithValue, getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const { payload } = args;
      const response = await api.cloudAccountsPost({
        AccountType: payload.AccountType,
        ClientID: payload.ClientID,
        ClientSecret: payload.ClientSecret,
        SubscriptionID: payload.SubscriptionID,
        TenantID: payload.TenantID,
      });
      showSuccessToast("Account connected successfully");
      return response;
    } catch (e) {
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong..");
    }
  }
);

export const disconnectIntegrationById = createAsyncThunk<void, { id: number }>(
  "integration/disconnectIntegrationById",
  async (arg, { rejectWithValue, getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new IntegrationsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const response = (await api.integrationsIdDelete(arg.id)).data;
      showSuccessToast("Cloud account disconnected successfully");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const disconnectIntegrationAccount = createAsyncThunk(
  "integration/disconnectIntegrationAccount",
  async (cloudAccountUUID: string, { rejectWithValue, getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new AccountsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const response = await api.cloudAccountsCloudAccountUUIDDisconnectPut(cloudAccountUUID);
      return response;
    } catch (e) {
      return rejectWithValue("Something went wrong..");
    }
  }
);

export const connectAWSIntegrationManual = createAsyncThunk(
  "integration/connectAWSIntegrationManual",
  async (
    args: { payload: { access_key: string; secret_key: string } },
    { getState, rejectWithValue }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.integrationsAwsPost(payload)).data;
      showSuccessToast("AWS Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectAzureIntegrationManual = createAsyncThunk(
  "integration/connectAzureIntegrationManual",
  async (
    args: {
      payload: {
        subscription_id: string;
        tenant_id: string;
        client_id: string;
        client_secret: string;
      };
    },
    { getState, rejectWithValue }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.integrationsAzurePost(payload)).data;
      showSuccessToast("AZURE Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectGcpIntegrationManual = createAsyncThunk(
  "integration/connectGcpIntegrationManual",
  async (args: { file: File }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const response = (await api.integrationsGcpPost(args.file)).data;
      showSuccessToast("GSP Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectMicrosoft365IntegrationManual = createAsyncThunk(
  "integration/connectMicrosoft365IntegrationManual",
  async (
    args: {
      payload: {
        tenant_id: string;
        client_id: string;
        client_secret: string;
      };
    },
    { getState, rejectWithValue }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.integrationsMicrosoft365Post(payload)).data;
      showSuccessToast("Microsoft365 Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectGoogleWorkspaceIntegrationManual = createAsyncThunk(
  "integration/connectGoogleWorkspaceIntegrationManual",
  async (args: { file: File }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const response = (await api.integrationsGoogleWorkspacePost(args.file)).data;
      showSuccessToast("Google Workspace Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectGithubIntegrationManual = createAsyncThunk(
  "integration/connectGithubIntegrationManual",
  async (args: { payload: { base_url: string; token: string } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const { payload } = args;
      const response = (await api.integrationsGithubPost(payload)).data;
      showSuccessToast("Github Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectSlackIntegrationManual = createAsyncThunk(
  "integration/connectSlackIntegrationManual",
  async (args: { payload: { token: string } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    try {
      const { payload } = args;
      const response = (await api.integrationsSlackPost(payload)).data;
      showSuccessToast("Slack Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectSnykIntegrationManual = createAsyncThunk(
  "integration/connectSnykIntegrationManual",
  async (args: { payload: { token: string } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.postSnykIntegrationConfig(payload)).data;
      showSuccessToast("SnykI Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectCheckmarxIntegrationManual = createAsyncThunk(
  "integration/connectCheckmarxIntegrationManual",
  async (args: { payload: { refresh_token: string } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.postCheckmarxIntegrationConfig(payload)).data;
      showSuccessToast("Checkmarx Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const connectClickupIntegrationManual = createAsyncThunk(
  "integration/connectClickupIntegrationManual",
  async (args: { payload: { token: string } }, { getState, rejectWithValue }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new CloudConfigApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );

    try {
      const { payload } = args;
      const response = (await api.integrationsClickupPost(payload)).data;
      showSuccessToast("Clickup Integration setup successfully!");
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

// Slice
export const integrationAWSSlice = createSlice({
  name: "integration",
  initialState: {
    integrationList: [] as Integration[],
    awsIntegrationConfig: {} as AwsIntegrationConfig,
    connectCloudProviderIntegrationManualIsLoading: true,
    cloudAccounts: [] as CloudAccounts,
    integrationListIsLoading: false,
    cloudAccountsIsLoading: false,
    disconnectIntegrationIsLoading: true,
    disconnectIntegrationByIdIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIntegrationsList.fulfilled, (state, action) => {
      state.integrationListIsLoading = true;
      state.integrationList = action.payload;
    });
    builder.addCase(getIntegrationsList.pending, (state) => {
      state.integrationListIsLoading = false;
    });
    builder.addCase(getIntegrationsList.rejected, (state) => {
      state.integrationListIsLoading = false;
    });
    builder.addCase(getAWSIntegrationConfig.fulfilled, (state, action) => {
      state.awsIntegrationConfig = action.payload;
    });
    builder.addCase(connectCloudProviderIntegrationManual.fulfilled, (state) => {
      state.connectCloudProviderIntegrationManualIsLoading = false;
    });
    builder.addCase(connectCloudProviderIntegrationManual.rejected, (state) => {
      state.connectCloudProviderIntegrationManualIsLoading = true;
    });
    builder.addCase(connectCloudProviderIntegrationManual.pending, (state) => {
      state.connectCloudProviderIntegrationManualIsLoading = true;
    });
    builder.addCase(getCloudAccounts.fulfilled, (state, action) => {
      state.cloudAccountsIsLoading = false;
      state.cloudAccounts = action.payload;
    });
    builder.addCase(getCloudAccounts.rejected, (state) => {
      state.cloudAccountsIsLoading = true;
    });
    builder.addCase(getCloudAccounts.pending, (state) => {
      state.cloudAccountsIsLoading = true;
    });
    builder.addCase(disconnectIntegrationAccount.fulfilled, (state) => {
      state.disconnectIntegrationIsLoading = false;
    });
    builder
      .addCase(disconnectIntegrationAccount.pending, (state) => {
        state.disconnectIntegrationIsLoading = true;
      })
      .addCase("application/logout", (state) => {
        state.awsIntegrationConfig = {};
        state.cloudAccounts = [];
        state.integrationList = [];
      });
    builder.addCase(disconnectIntegrationById.pending, (state) => {
      state.disconnectIntegrationByIdIsLoading = true;
    });
    builder.addCase(disconnectIntegrationById.fulfilled, (state) => {
      state.disconnectIntegrationByIdIsLoading = false;
    });
    builder.addCase(disconnectIntegrationById.rejected, (state) => {
      state.disconnectIntegrationByIdIsLoading = false;
    });
  },
});

export const selectAWSIntegration = (state: RootState) => state.integration;

export const selectCloudAccounts = (state: RootState, type: CloudAccountAccountTypeEnum) =>
  state.integration.cloudAccounts.filter((account) => account.AccountType === type);

export const selectIntegrations = (state: RootState) =>
  [...new Set(state.integration.cloudAccounts.map((account) => account.AccountType))].filter((el) =>
    currentEnabledTypes.includes(el!)
  );

export const selectCloudAccountsLoading = (state: RootState) =>
  state.integration.cloudAccountsIsLoading;

export const selectCloudAccount = (state: RootState, type: CloudAccountAccountTypeEnum) =>
  state.integration.cloudAccounts.find((account) => account.AccountType === type);

export const selectCloudAccountByName = (state: RootState, name: string) =>
  state.integration.cloudAccounts.find((account) => account.Name === name);

export const selectCloudAccountsCount = (state: RootState, type?: CloudAccountAccountTypeEnum) => {
  if (!type) return state.integration.cloudAccounts.length;
  return state.integration.cloudAccounts.filter(
    (account) => account.AccountType === type.toUpperCase()
  ).length;
};
export const selectDisconnectIntegration = (state: RootState) =>
  state.integration.disconnectIntegrationByIdIsLoading;

export default integrationAWSSlice.reducer;
