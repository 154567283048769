import { WhoAmIUser } from "mycio-openapi";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getVersion, getUser } from "store/application";
import { initConfig } from "store/config";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getLoggedInUserAvatar } from "store/users";
import Cookies from "js-cookie";

interface Payload {
  data: WhoAmIUser;
  tenantAvailable: boolean;
}

export default function AuthListener({ setLoading }: { setLoading: (val: boolean) => void }) {
  const dispatch = useAppDispatch();
  const { pathname, asPath, isReady, replace } = useRouter();
  const authenticated = useAppSelector((state) => state.application.authenticated);
  const publicRoutes = [
    "/register",
    "/login",
    "/reset_password",
    "/acknowledge-policy",
    "/accept-invite",
    "/vendor-questionnaire",
  ];

  const load = async () => {
    const accessToken = Cookies.get("token");
    const refreshToken = Cookies.get("refresh_token");
    const isAuth = accessToken && refreshToken;

    const redirectPath =
      publicRoutes.includes(asPath) && isAuth && pathname !== "/acknowledge-policy" ? "/" : asPath;

    if (!isAuth && !publicRoutes.includes(pathname)) await replace("/login");

    if (isAuth) {
      const { payload } = (await dispatch(getUser())) as { payload: Payload };

      if (!payload.tenantAvailable && isAuth) return replace("/register");

      await replace(redirectPath);
      await dispatch(getLoggedInUserAvatar());
      // await dispatch(getConfig());
      await dispatch(initConfig());
      await dispatch(getVersion());
    }
    return true;
  };

  useEffect(() => {
    if (isReady) void load().then(() => setLoading(false));
  }, [isReady, authenticated]);

  return <></>;
}
