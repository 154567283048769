import {
  clearAllLocalStorageItemsIncludes,
  getAllLocalStorageItemIncludes,
} from "utils/window/localStorage";
import { isRunningOnServer } from "utils/window/ssr";

const userDataPrefix = "MyCIOUser";

export const setLocalStorageUserData = (key: string, value: string) => {
  if (!isRunningOnServer) window.localStorage.setItem(`${userDataPrefix}${key}`, value);
};

export const getLocalStorageUserData = (key: string) => {
  if (!isRunningOnServer) return window.localStorage.getItem(`${userDataPrefix}${key}`);
  return "";
};

export const getAllLocalStorageUserData = () => getAllLocalStorageItemIncludes(userDataPrefix);

export const clearAllLocalStorageUserData = () => clearAllLocalStorageItemsIncludes(userDataPrefix);
