import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { FeatureFlagsApi, Configuration, FeatureFlags } from "mycio-openapi";

export interface IFeatureFlags {
  [key: string]: boolean;
}

export const getFeatureFlags = createAsyncThunk(
  "featureFlags/getFeatureFlags",
  async (_, thunkAPI) => {
    const accessToken = (thunkAPI.getState() as RootState).application.currentUser.userToken;
    const api = new FeatureFlagsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const featureFlags = (await api.featureFlagsGet()).data;
    return featureFlags;
  }
);

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState: {} as FeatureFlags,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureFlags.fulfilled, (state, action) => action.payload);
  },
});

export default featureFlagsSlice.reducer;

export const selectFeatureFlag = (state: RootState, feature: string) => state.featureFlags[feature];
