import type { RootState } from "store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  VendorApi,
  Configuration,
  FailedResponse,
  VendorGet200Response,
  VendorPostRequest,
  VendorCategory,
  Vendor,
  VendorIdPatchRequest,
  VendorDocumentTypesGet200Response,
  VendorIdDocumentsGet200Response,
  VendorDocumentsIdFileGet200Response,
  VendorIdSecurityReviewStatusHistoryGet200Response,
} from "mycio-openapi";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";

interface PaginationPayload {
  id?: number;
  page?: number;
  limit?: number;
}

export interface VendorDocumentPostPatchRequest {
  id: number;
  file?: File;
  title?: string;
  typeId?: number;
  expiryDate?: string;
}

export const getVendors = createAsyncThunk<VendorGet200Response, PaginationPayload | undefined>(
  "vendors/getVendors",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.vendorGet(arg?.page, arg?.limit)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVendorById = createAsyncThunk<Vendor, number>(
  "vendors/getVendorById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.vendorIdGet(id)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addVendor = createAsyncThunk(
  "vendors/addVendor",
  async (payload: VendorPostRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.vendorPost(payload);
      const { data } = response;
      showSuccessToast("Vendor Added successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateVendor = createAsyncThunk(
  "vendors/updateVendor",
  async (arg: { id: number; data: VendorIdPatchRequest }, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.vendorIdPatch(arg.id, arg.data);
      const { data } = response;
      showSuccessToast("Vendor Updated successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVendorCategory = createAsyncThunk<VendorCategory[], undefined>(
  "vendors/getVendorCategory",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.vendorCategoryGet()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVendorDocumentTypes = createAsyncThunk<
  VendorDocumentTypesGet200Response,
  undefined
>("vendors/getVendorDocumentTypes", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.vendorDocumentTypesGet()).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getVendorDocumentById = createAsyncThunk<
  VendorIdDocumentsGet200Response,
  PaginationPayload
>("vendors/getVendorDocumentById", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = (await api.vendorIdDocumentsGet(arg.id as number, arg.page, arg.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const addVendorDocumentById = createAsyncThunk(
  "vendors/addVendorDocumentById",
  async (arg: VendorDocumentPostPatchRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.vendorIdDocumentsPost(
        arg.id,
        arg.file,
        arg.title,
        arg.typeId,
        arg.expiryDate
      );
      const { data } = response;
      showSuccessToast("Vendor Added successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateVendorDocumentById = createAsyncThunk(
  "vendors/updateVendorDocumentById",
  async (arg: VendorDocumentPostPatchRequest, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.vendorDocumentsIdPatch(
        arg.id,
        arg.file,
        arg.title,
        arg.typeId,
        arg.expiryDate
      );
      const { data } = response;
      showSuccessToast("Vendor Added successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteVendorDocumentById = createAsyncThunk(
  "vendors/deleteVendorDocumentById",
  async (id: number, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VendorApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.vendorDocumentsIdDelete(id);
      const { data } = response;
      showSuccessToast("Vendor Deleted successfully");
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteVendorDocumentFileById = createAsyncThunk<
  VendorDocumentsIdFileGet200Response,
  number
>("vendors/deleteVendorDocumentFileById", async (id, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VendorApi({ accessToken } as Configuration, process.env.NEXT_PUBLIC_MYCIO_API);
    const response = await api.vendorDocumentsIdFileGet(id);
    const { data } = response;
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const vendorsSlice = createSlice({
  name: "vendors",
  initialState: {
    vendors: {} as VendorGet200Response,
    vendorsLoading: false,
    vendor: {} as Vendor,
    vendorLoading: false,
    addVendorLoading: false,
    updateVendorLoading: false,
    vendorCategory: [] as VendorCategory[],
    vendorCategoryLoading: false,
    vendorDocumentTypes: {} as VendorDocumentTypesGet200Response,
    vendorDocumentTypesLoading: false,
    vendorDocuments: {} as VendorIdDocumentsGet200Response,
    vendorDocumentsLoading: false,
    vendorAddDocumentsLoading: false,
    vendorDocumentFile: {} as VendorDocumentsIdFileGet200Response,
    vendorDocumentFileLoading: false,
    VendorIdSecurityReviewStatusHistory: {} as VendorIdSecurityReviewStatusHistoryGet200Response,
    VendorIdSecurityReviewStatusHistoryLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.vendorsLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.vendors = action.payload;
        state.vendorsLoading = false;
      })
      .addCase(getVendors.rejected, (state) => {
        state.vendorsLoading = false;
      })

      .addCase(addVendor.pending, (state) => {
        state.addVendorLoading = true;
      })
      .addCase(addVendor.fulfilled, (state) => {
        state.addVendorLoading = false;
      })
      .addCase(addVendor.rejected, (state) => {
        state.addVendorLoading = false;
      })

      .addCase(updateVendor.pending, (state) => {
        state.updateVendorLoading = true;
      })
      .addCase(updateVendor.fulfilled, (state) => {
        state.updateVendorLoading = false;
      })
      .addCase(updateVendor.rejected, (state) => {
        state.updateVendorLoading = false;
      })

      .addCase(getVendorCategory.pending, (state) => {
        state.vendorCategoryLoading = true;
      })
      .addCase(getVendorCategory.fulfilled, (state, action) => {
        state.vendorCategory = action.payload;
        state.vendorCategoryLoading = false;
      })
      .addCase(getVendorCategory.rejected, (state) => {
        state.vendorCategoryLoading = false;
      })

      .addCase(getVendorById.pending, (state) => {
        state.vendorLoading = true;
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.vendor = action.payload;
        state.vendorLoading = false;
      })
      .addCase(getVendorById.rejected, (state) => {
        state.vendorLoading = false;
      })

      .addCase(getVendorDocumentTypes.pending, (state) => {
        state.vendorDocumentTypesLoading = true;
      })
      .addCase(getVendorDocumentTypes.fulfilled, (state, action) => {
        state.vendorDocumentTypes = action.payload;
        state.vendorDocumentTypesLoading = false;
      })
      .addCase(getVendorDocumentTypes.rejected, (state) => {
        state.vendorDocumentTypesLoading = false;
      })

      .addCase(getVendorDocumentById.pending, (state) => {
        state.vendorDocumentsLoading = true;
      })
      .addCase(getVendorDocumentById.fulfilled, (state, action) => {
        state.vendorDocuments = action.payload;
        state.vendorDocumentsLoading = false;
      })
      .addCase(getVendorDocumentById.rejected, (state) => {
        state.vendorDocumentsLoading = false;
      })

      .addCase(addVendorDocumentById.pending, (state) => {
        state.vendorAddDocumentsLoading = true;
      })
      .addCase(addVendorDocumentById.fulfilled, (state) => {
        state.vendorAddDocumentsLoading = false;
      })
      .addCase(addVendorDocumentById.rejected, (state) => {
        state.vendorAddDocumentsLoading = false;
      })

      .addCase(deleteVendorDocumentFileById.pending, (state) => {
        state.vendorDocumentFileLoading = true;
      })
      .addCase(deleteVendorDocumentFileById.fulfilled, (state, action) => {
        state.vendorDocumentFile = action.payload;
        state.vendorDocumentFileLoading = false;
      })
      .addCase(deleteVendorDocumentFileById.rejected, (state) => {
        state.vendorDocumentFileLoading = false;
      });
  },
});

export default vendorsSlice.reducer;
