import type { RootState } from "store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Configuration,
  ControlsApi,
  ControlsControlIdPoliciesGet200Response,
  ControlsIdAssetsGet200Response,
  ControlsIdEvidencesGet200Response,
  FailedResponse,
  Framework,
  ProgramsApi,
  ProgramsFrameworkIdRequirementsGet200Response,
  RequirementsRequirementIdControlsGet200Response,
} from "mycio-openapi";
import { showErrorToast } from "utils/toasts";
import axios from "axios";

interface PaginationPayloadNumber {
  id: number;
  page?: number;
  limit?: number;
}
interface PaginationPayloadString {
  id: string;
  page?: number;
  limit?: number;
}
interface ControlRelevantPoliciesArgs {
  controlId: number;
  page?: number;
  limit?: number;
}

export const getPrograms = createAsyncThunk<Framework[]>(
  "programMap/getPrograms",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ProgramsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.programsGet()).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getProgramsFrameworkIdRequirements = createAsyncThunk<
  ProgramsFrameworkIdRequirementsGet200Response,
  PaginationPayloadNumber
>("programMap/getProgramsFrameworkIdRequirements", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new ProgramsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.programsFrameworkIdRequirementsGet(arg?.id, arg?.page, arg?.limit))
      .data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getRequirementsRequirementIdControls = createAsyncThunk<
  RequirementsRequirementIdControlsGet200Response,
  PaginationPayloadString
>("programMap/getRequirementsRequirementIdControls", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new ProgramsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (
      await api.requirementsRequirementIdControlsGet(arg?.id, arg?.page, arg?.limit)
    ).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getProgramAssetsControlById = createAsyncThunk(
  "resources/getProgramAssetsControlById",
  async (
    args: {
      id: number;
      compliant?: boolean;
      name?: string;
      page?: number;
      limit?: number;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.controlsIdAssetsGet(args.id, args.compliant, args.name, args?.page, args?.limit)
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getProgramEvidencesControlById = createAsyncThunk(
  "resources/getProgramEvidencesControlById",
  async (args: { id: number; page?: number; limit?: number }, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.controlsIdEvidencesGet(args.id, args?.page, args?.limit)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getControlRelevantPolicies = createAsyncThunk<
  ControlsControlIdPoliciesGet200Response,
  ControlRelevantPoliciesArgs
>("programMap/getControlRelevantPolicies", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new ControlsApi(
      new Configuration({ accessToken }),
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = await api.controlsControlIdPoliciesGet(arg.controlId, arg.page, arg.limit);
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue({ message: "Something went wrong" });
  }
});

export const programMapSlice = createSlice({
  name: "programMap",
  initialState: {
    programsMaps: [] as Framework[],
    programsMapsLoading: false,
    programsFrameworkIdRequirements: {} as ProgramsFrameworkIdRequirementsGet200Response,
    programsFrameworkIdRequirementsLoading: false,
    requirementsRequirementIdControls: {} as RequirementsRequirementIdControlsGet200Response,
    requirementsRequirementIdControlsLoading: false,
    programEvidences: {} as ControlsIdEvidencesGet200Response,
    programEvidencesLoading: false,
    programAssets: {} as ControlsIdAssetsGet200Response,
    programAssetsLoading: false,
    controlRelevantPolicies: {} as ControlsControlIdPoliciesGet200Response,
    controlRelevantPoliciesIsLoading: false,
  },
  reducers: {
    clearProgramsFrameworkIdRequirements: (state) => {
      state.programsFrameworkIdRequirements = {};
    },
    resetRequirementIdControls(state) {
      state.requirementsRequirementIdControls = {};
    },
    clearProgramDetails(state) {
      state.programEvidences = {};
      state.programAssets = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrograms.pending, (state) => {
        state.programsMapsLoading = true;
      })
      .addCase(getPrograms.fulfilled, (state, action) => {
        state.programsMaps = action.payload;
        state.programsMapsLoading = false;
      })
      .addCase(getPrograms.rejected, (state) => {
        state.programsMapsLoading = false;
      })

      .addCase(getProgramsFrameworkIdRequirements.pending, (state) => {
        state.programsFrameworkIdRequirementsLoading = true;
      })
      .addCase(getProgramsFrameworkIdRequirements.fulfilled, (state, action) => {
        const currentRequirements = state.programsFrameworkIdRequirements.requirements;
        const incomingRequirements = action.payload.requirements;

        if (currentRequirements && incomingRequirements && currentRequirements.length > 0) {
          state.programsFrameworkIdRequirements.requirements = [
            ...currentRequirements,
            ...incomingRequirements,
          ];
        } else {
          state.programsFrameworkIdRequirements = action.payload;
        }
        state.programsFrameworkIdRequirementsLoading = false;
      })
      .addCase(getProgramsFrameworkIdRequirements.rejected, (state) => {
        state.programsFrameworkIdRequirementsLoading = false;
      })

      .addCase(getRequirementsRequirementIdControls.pending, (state) => {
        state.requirementsRequirementIdControlsLoading = true;
      })
      .addCase(getRequirementsRequirementIdControls.fulfilled, (state, action) => {
        state.requirementsRequirementIdControls = action.payload;
        state.requirementsRequirementIdControlsLoading = false;
      })
      .addCase(getRequirementsRequirementIdControls.rejected, (state) => {
        state.requirementsRequirementIdControlsLoading = false;
      })

      .addCase(getProgramAssetsControlById.pending, (state) => {
        state.programAssetsLoading = true;
      })
      .addCase(getProgramAssetsControlById.fulfilled, (state, action) => {
        state.programAssets = action.payload;
        state.programAssetsLoading = false;
      })
      .addCase(getProgramAssetsControlById.rejected, (state) => {
        state.programAssetsLoading = false;
      })

      .addCase(getProgramEvidencesControlById.pending, (state) => {
        state.programEvidencesLoading = true;
      })
      .addCase(getProgramEvidencesControlById.fulfilled, (state, action) => {
        state.programEvidences = action.payload;
        state.programEvidencesLoading = false;
      })
      .addCase(getProgramEvidencesControlById.rejected, (state) => {
        state.programEvidencesLoading = false;
      })
      .addCase(getControlRelevantPolicies.pending, (state) => {
        state.controlRelevantPoliciesIsLoading = true;
      })
      .addCase(getControlRelevantPolicies.fulfilled, (state, action) => {
        state.controlRelevantPolicies = action.payload;
        state.controlRelevantPoliciesIsLoading = false;
      })
      .addCase(getControlRelevantPolicies.rejected, (state) => {
        state.controlRelevantPoliciesIsLoading = false;
      });
  },
});

export const selectControlRelevantPolicies = (state: RootState) => {
  const { controlRelevantPoliciesIsLoading, controlRelevantPolicies } = state.programMap;

  return {
    controlRelevantPolicies,
    controlRelevantPoliciesIsLoading,
  };
};

export const {
  clearProgramsFrameworkIdRequirements,
  resetRequirementIdControls,
  clearProgramDetails,
} = programMapSlice.actions;

export default programMapSlice.reducer;
