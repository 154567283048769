import { IconProps } from "components/icons/Icon.interface";

const GoogleWorkspaceLogo = ({ className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 3995.04 512"
  >
    <defs>
      <clipPath id="a" transform="translate(0 0)">
        <rect width="3996" height="512" fill="none" />
      </clipPath>
    </defs>
    <polygon
      points="2086.62 38.61 2133.66 38.61 2040.07 389.45 1994.01 389.45 1918.55 158.17 1916.59 158.17 1841.13 389.45 1795.07 389.45 1697.56 38.61 1744.6 38.61 1818.59 314.48 1820.55 314.48 1896.5 85.65 1938.64 85.65 2014.59 314.48 2016.55 314.48 2086.62 38.61"
      fill="#5f6368"
    />
    <g clipPath="url(#a)">
      <path
        d="M2129.4,269.4q0-55.36,34.79-91.63,35.28-36.26,88.69-36.26t88.2,36.26q35.28,36.27,35.28,91.63,0,55.86-35.28,91.63-34.8,36.27-88.2,36.26T2164.19,361q-34.8-36.25-34.79-91.63m45.08,0q0,38.72,22.54,62.72t55.86,24q33.32,0,55.86-24t22.54-62.72q0-38.22-22.54-62.23-23-24.49-55.86-24.5T2197,207.17q-22.55,24-22.54,62.23"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <path
        d="M2453.43,389.45h-45.08V149.35h43.12v39.2h2q6.86-19.11,28.18-32.59t41.89-13.47q19.59,0,33.32,5.88L2543.1,192q-8.34-3.42-26.46-3.43-25.48,0-44.34,20.58t-18.87,48Z"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <polygon
        points="2788.88 389.45 2734.49 389.45 2659.52 276.26 2622.77 312.52 2622.77 389.45 2577.69 389.45 2577.69 38.61 2622.77 38.61 2622.77 254.7 2726.65 149.35 2784.47 149.35 2784.47 151.31 2690.88 244.41 2788.88 387.49 2788.88 389.45"
        fill="#5f6368"
      />
      <path
        d="M2983.94,322.81q0,31.37-27.44,52.92t-69.09,21.56q-36.26,0-63.7-18.87a104,104,0,0,1-39.2-49.73l40.18-17.15q8.82,21.57,25.73,33.56a62.49,62.49,0,0,0,37,12q21.57,0,36-9.31t14.45-22.05q0-23-35.28-33.81l-41.16-10.29Q2791.38,264,2791.37,214q0-32.82,26.71-52.68t68.35-19.84q31.86,0,57.58,15.19t36,40.67L2939.84,214a50.62,50.62,0,0,0-22.29-23.77,70,70,0,0,0-34.55-8.57,57.92,57.92,0,0,0-31.6,8.82q-14,8.82-14,21.56,0,20.58,38.71,29.4l36.26,9.31q71.54,17.64,71.54,72"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <path
        d="M3140.49,397.29q-26.46,0-48.26-11.27t-33.57-29.89h-2l2,33.32V495.29h-45.08V149.35h43.12v33.32h2q11.76-18.61,33.57-29.89t48.26-11.27q47.52,0,80.36,37.24,33.81,37.74,33.81,90.65,0,53.42-33.81,90.65-32.83,37.24-80.36,37.24m-7.35-41.16q32.34,0,54.39-24.5,22-24,22-62.23,0-37.72-22-62.23t-54.39-24.5q-32.83,0-54.88,24.5-21.57,24.51-21.56,62.23,0,38.22,21.56,62.72,22,24,54.88,24"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <path
        d="M3378.14,141.51q50,0,78.89,26.7t28.91,73.26v148h-43.12V356.13h-2q-27.93,41.16-74.48,41.16-39.69,0-66.4-23.52t-26.7-58.8q0-37.23,28.17-59.29t75.22-22.05q40.17,0,66.15,14.7V238q0-23.52-18.62-39.94a63.88,63.88,0,0,0-43.61-16.41q-37.74,0-59.78,31.85l-39.69-25q32.82-47,97-47M3319.83,316q0,17.64,14.94,29.4t35,11.76q28.41,0,50.71-21.07t22.3-49.49q-21.08-16.65-58.8-16.66-27.45,0-45.82,13.23T3319.83,316"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <path
        d="M3638.77,397.29q-53.42,0-88.69-36.26-34.8-37.23-34.79-91.63,0-55.36,34.79-91.63,35.28-36.26,88.69-36.26,36.75,0,64.19,18.37t41.16,50.72L3703,227.75q-19.11-45.08-67.13-45.08-30.87,0-53.41,25-22.05,25-22.05,61.74t22.05,61.74q22.53,25,53.41,25,49.49,0,69.58-45.08l40.18,17.15q-13.23,32.34-41.41,50.71t-65.41,18.38"
        transform="translate(0 0)"
        fill="#5f6368"
      />
      <path
        d="M3881.36,397.29q-52.92,0-87.22-36.26t-34.3-91.63q0-54.87,33.32-91.39t85.26-36.5q53.4,0,85,34.54T3995,272.83l-.49,4.9H3805.9q1,35.28,23.52,56.84t53.9,21.56q43.11,0,67.62-43.12l40.18,19.6a119.71,119.71,0,0,1-44.84,47.53q-28.67,17.14-64.92,17.15m-72-156.8H3947q-2-25-20.34-41.41t-49.24-16.41q-25.49,0-43.86,15.68t-24.25,42.14"
        transform="translate(0 0)"
        fill="#5f6368"
      />
    </g>
    <g clipPath="url(#a)">
      <path
        d="M202.4,235.58V181.32H384a178.13,178.13,0,0,1,2.93,33.27c0,40.68-11.13,91.06-47,126.9-34.87,36.33-79.42,55.71-138.5,55.71C92,397.2,0,308.07,0,198.6S92,0,201.45,0C262,0,305.08,23.74,337.53,54.74L299.27,93C276,71.21,244.55,54.25,201.45,54.25c-79.9,0-142.37,64.42-142.37,144.35S121.55,343,201.45,343c51.82,0,81.36-20.83,100.24-39.72,15.39-15.39,25.53-37.47,29.46-67.65Z"
        transform="translate(0 0)"
        fill="#4285f4"
      />
      <path
        d="M663.64,269.32C663.64,343,606,397.2,535.32,397.2S407,343,407,269.32c0-74.11,57.63-127.88,128.34-127.88s128.32,53.77,128.32,127.88m-56.17,0c0-46-33.41-77.5-72.15-77.5s-72.16,31.48-72.16,77.5c0,45.53,33.41,77.5,72.16,77.5s72.15-32,72.15-77.5"
        transform="translate(0 0)"
        fill="#ea4335"
      />
      <path
        d="M943.59,269.32C943.59,343,886,397.2,815.27,397.2S686.94,343,686.94,269.32c0-74.11,57.62-127.88,128.33-127.88s128.32,53.77,128.32,127.88m-56.17,0c0-46-33.41-77.5-72.15-77.5s-72.16,31.48-72.16,77.5c0,45.53,33.41,77.5,72.16,77.5s72.15-32,72.15-77.5"
        transform="translate(0 0)"
        fill="#fbbc04"
      />
      <path
        d="M1211.93,149.19v229.6c0,94.46-55.69,133.21-121.55,133.21-62,0-99.28-41.66-113.32-75.56L1026,416.09c8.71,20.83,30,45.53,64.41,45.53,42.13,0,68.28-26.16,68.28-75.08v-18.4h-1.94c-12.59,15.5-36.8,29.06-67.31,29.06-63.93,0-122.52-55.71-122.52-127.39,0-72.18,58.59-128.37,122.52-128.37,30.51,0,54.72,13.56,67.31,28.58h1.94V149.19Zm-49.4,120.62c0-45.05-30-78-68.28-78-38.74,0-71.19,32.94-71.19,78,0,44.56,32.45,77,71.19,77,38.26,0,68.28-32.45,68.28-77"
        transform="translate(0 0)"
        fill="#4285f4"
      />
      <rect x="1250.9" y="14.82" width="56.17" height="374.63" fill="#34a853" />
      <path
        d="M1524,311.46l43.58,29.06c-14,20.83-47.94,56.68-106.54,56.68-72.64,0-126.88-56.19-126.88-127.88,0-76,54.73-127.88,120.58-127.88,66.35,0,98.79,52.8,109.45,81.38l5.81,14.53-171,70.72c13.08,25.67,33.42,38.75,62,38.75s48.43-14,63-35.36m-134.15-46L1504.14,218c-6.3-16-25.18-27.12-47.46-27.12-28.57,0-68.28,25.19-66.83,74.6"
        transform="translate(0 0)"
        fill="#ea4335"
      />
    </g>
  </svg>
);

export default GoogleWorkspaceLogo;
