import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "store/application";
import configReducer from "store/config";
import usersReducer from "store/users";
import tasksReducer from "store/tasks";
import controlsReducer from "store/controls";
import loadingReducer from "./loading";
import featureFlagsReducer from "./featureFlags";
import resourcesReducer from "./resources";
import integrationReducer from "./integrations";
import evidencesReducer from "./evidences";
import documentsReducer from "./documents";
import devicesReducer from "./devices";
import vulnerabilitiesReducer from "./vulnerabilities";
import accountsReducer from "./accounts";
import cloudAssetsReducer from "./cloudAssets";
import programMapReducer from "./programMap";
import risksReducer from "./risks";
import organizationReducer from "./organization";
import vendorsReducer from "./vendors";
import questionnaireReducer from "./questionnaire";

const store = configureStore({
  reducer: {
    config: configReducer,
    application: applicationReducer,
    users: usersReducer,
    tasks: tasksReducer,
    loading: loadingReducer,
    controls: controlsReducer,
    featureFlags: featureFlagsReducer,
    resources: resourcesReducer,
    integration: integrationReducer,
    evidences: evidencesReducer,
    documents: documentsReducer,
    devices: devicesReducer,
    accounts: accountsReducer,
    vulnerabilities: vulnerabilitiesReducer,
    cloudAssets: cloudAssetsReducer,
    programMap: programMapReducer,
    risks: risksReducer,
    organization: organizationReducer,
    vendors: vendorsReducer,
    questionnaire: questionnaireReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;
