import type { RootState } from "store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "utils/toasts";
import axios from "axios";
import {
  Configuration,
  FailedResponse,
  VulnerabilitiesApi,
  VulnerabilitiesGet200Response,
  VulnerabilitiesIdOwnerPatchRequest,
  Vulnerability,
} from "mycio-openapi";

interface PaginationPayload {
  page?: number;
  limit?: number;
}

interface OwnerById {
  id: number;
  vulnerabilitiesIdOwnerPatchRequest: VulnerabilitiesIdOwnerPatchRequest;
}

export const getVulnerabilities = createAsyncThunk<
  VulnerabilitiesGet200Response,
  PaginationPayload | undefined
>("vulnerabilities/getVulnerabilities", async (arg, { rejectWithValue, getState }) => {
  try {
    const accessToken = (getState() as RootState).application.currentUser.userToken;
    const api = new VulnerabilitiesApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    const response = (await api.vulnerabilitiesGet(arg?.page, arg?.limit)).data;
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as FailedResponse).Reason) {
        const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
        showErrorToast(errorMessage);
        return rejectWithValue({ message: errorMessage });
      }
    }
    showErrorToast("Something went wrong.");
    return rejectWithValue("Something went wrong");
  }
});

export const getVulnerabilityById = createAsyncThunk<Vulnerability, number>(
  "vulnerabilities/getVulnerabilityById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VulnerabilitiesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.vulnerabilitiesIdGet(id)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const patchVulnerabilityOwnerById = createAsyncThunk<void, OwnerById>(
  "vulnerabilities/patchVulnerabilityOwnerById",
  async (args, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new VulnerabilitiesApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      await api.vulnerabilitiesIdOwnerPatch(args.id, args.vulnerabilitiesIdOwnerPatchRequest);
      showSuccessToast("Owner updated successfully.");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const vulnerabilitiesSlice = createSlice({
  name: "vulnerabilities",
  initialState: {
    vulnerabilities: {} as VulnerabilitiesGet200Response,
    vulnerabilitiesLoading: false,
    vulnerabilityById: {} as Vulnerability,
    vulnerabilityByIdLoading: false,
    patchVulnerabilityOwnerByIdLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVulnerabilities.pending, (state) => {
        state.vulnerabilitiesLoading = true;
      })
      .addCase(getVulnerabilities.fulfilled, (state, action) => {
        state.vulnerabilities = action.payload;
        state.vulnerabilitiesLoading = false;
      })
      .addCase(getVulnerabilities.rejected, (state) => {
        state.vulnerabilitiesLoading = false;
      })

      .addCase(getVulnerabilityById.pending, (state) => {
        state.vulnerabilityByIdLoading = true;
      })
      .addCase(getVulnerabilityById.fulfilled, (state, action) => {
        state.vulnerabilityById = action.payload;
        state.vulnerabilityByIdLoading = false;
      })
      .addCase(getVulnerabilityById.rejected, (state) => {
        state.vulnerabilityByIdLoading = false;
      })

      .addCase(patchVulnerabilityOwnerById.pending, (state) => {
        state.patchVulnerabilityOwnerByIdLoading = true;
      })
      .addCase(patchVulnerabilityOwnerById.fulfilled, (state) => {
        state.patchVulnerabilityOwnerByIdLoading = false;
      })
      .addCase(patchVulnerabilityOwnerById.rejected, (state) => {
        state.patchVulnerabilityOwnerByIdLoading = false;
      })
      .addCase("application/logout", (state) => {
        state.vulnerabilities = {};
        state.vulnerabilityById = {};
      });
  },
});

export default vulnerabilitiesSlice.reducer;

export const selectVulnerabilities = (state: RootState) => {
  const vulnerabilities = state.vulnerabilities?.vulnerabilities;

  return {
    vulnerabilities,
    isLoading: state.vulnerabilities?.vulnerabilitiesLoading,
  };
};

export const selectVulnerability = (state: RootState) => {
  const vulnerability = state.vulnerabilities.vulnerabilityById;
  const isLoading = state.vulnerabilities.vulnerabilityByIdLoading;
  return { vulnerability, isLoading };
};
