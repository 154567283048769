import { IntegrationsCloudAssetsIdGetClassificationLevelEnum } from "mycio-openapi";

export interface IClassificationLevel {
  Confidential?: boolean;
  Internal?: boolean;
  NotApplicable?: boolean;
  Public?: boolean;
  Restricted?: boolean;
}
export interface ICompliance {
  compliant?: boolean;
  "Not Compliant"?: boolean;
}
export interface IAssetTypes {
  NonTechnica?: boolean;
  Employees?: boolean;
  Accounts?: boolean;
  Devices?: boolean;
  CloudResource?: boolean;
}
export interface FormData {
  Confidential?: boolean;
  Internal?: boolean;
  NotApplicable?: boolean;
  Public?: boolean;
  Restricted?: boolean;
  compliant?: boolean;
  "Not Compliant"?: boolean;
  NonTechnica?: boolean;
  Employees?: boolean;
  Accounts?: boolean;
  Devices?: boolean;
  CloudResource?: boolean;
  ownerId?: number;
  userId?: number;
  employeeId?: number;
}
export interface IFilteredDataType {
  classificationLevel?: string[];
  assetTypes?: string[];
  compliant?: boolean;
  ownerId?: number;
  employeeId?: number;
  userId?: number;
  searchKeyword?: string;
}

export enum ClassificationEnum {
  Public = "Public",
  Internal = "Internal",
  Confidential = "Confidential",
  Restricted = "Restricted",
  NotApplicable = "NotApplicable",
}
export enum CompliantEnum {
  Compliant = "Compliant",
  NotCompliant = "Not Compliant",
}
export enum AssetTypesEnum {
  NonTechnica = "Non-Technica",
  Employees = "Employees",
  Accounts = "Accounts",
  Devices = "Devices",
  CloudResource = "Cloud Resource",
}
export type FilterOptionType = {
  groupTitle: string;
  inputType: string;
  name: string;
  options?: {
    checked: boolean;
    name: string | IntegrationsCloudAssetsIdGetClassificationLevelEnum | AssetTypesEnum;
    label:
      | string
      | CompliantEnum
      | IntegrationsCloudAssetsIdGetClassificationLevelEnum
      | AssetTypesEnum;
  }[];
  selectData?: {
    id?: number;
    label?: string;
  }[];
};
export interface ISorterDataTypes {
  classificationLevel: IClassificationLevel;
  compliance: ICompliance;
  assetTypes: IAssetTypes;
  ownerId: number | undefined;
  userId: number | undefined;
  employeeId: number | undefined;
}
export interface IFilterQuery {
  classificationLevel?: string;
  compliance?: string;
  assetTypes?: string;
  ownerId?: string;
  userId?: string;
  employeeId?: string;
  searchKeyword?: string;
}
export interface TransformDataType {
  classificationLevel: IClassificationLevel;
  compliant: ICompliance;
  AssetTypes: IAssetTypes;
  ownerId: number | undefined;
  userId: number | undefined;
  employeeId: number | undefined;
}
