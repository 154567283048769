import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { controlFilter } from "components/FilterSidebar/FilterOptions";
import isString from "lodash/isString";
import type { RootState } from "store";
import {
  Configuration,
  ControlDetails,
  ControlsApi,
  ControlsGetSearchByEnum,
  ControlsIdAssetsGet200Response,
  ControlsIdEvidencesGet200Response,
  ControlsWithResources,
  FailedResponse,
  SkeldusControlsResponse,
} from "mycio-openapi";
import axios from "axios";
import { showErrorToast } from "utils/toasts";

interface PaginationPayload {
  compliant?: boolean;
  ownerId?: number;
  assetTypeId?: number;
  searchBy?: ControlsGetSearchByEnum;
  searchKeyword?: string;
  limit?: number;
  page?: number;
}

export const getControls = createAsyncThunk<SkeldusControlsResponse, PaginationPayload | undefined>(
  "controls/getControls",
  async (arg, { rejectWithValue, getState }) => {
    try {
      const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = await api.controlsGet(
        arg?.compliant,
        arg?.ownerId,
        arg?.assetTypeId,
        arg?.searchBy,
        arg?.searchKeyword,
        arg?.limit,
        arg?.page
      );
      const { data } = response;
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong..");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getControlById = createAsyncThunk<ControlDetails, number>(
  "resources/getEmployeeById",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.controlsIdGet(id)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getControlByIdAssets = createAsyncThunk(
  "resources/getControlByIdAssets",
  async (
    args: {
      id: number;
      compliant?: boolean;
      name?: string;
      page?: number;
      limit?: number;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (
        await api.controlsIdAssetsGet(args.id, args.compliant, args.name, args?.page, args?.limit)
      ).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getControlByIdEvidences = createAsyncThunk(
  "resources/getControlByIdEvidences",
  async (args: { id: number; page?: number; limit?: number }, { rejectWithValue, getState }) => {
    try {
      const accessToken = (getState() as RootState).application.currentUser.userToken;
      const api = new ControlsApi(
        { accessToken } as Configuration,
        process.env.NEXT_PUBLIC_MYCIO_API
      );
      const response = (await api.controlsIdEvidencesGet(args.id, args?.page, args?.limit)).data;
      return response;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as FailedResponse).Reason) {
          const errorMessage: string = (err.response?.data as FailedResponse).Reason as string;
          showErrorToast(errorMessage);
          return rejectWithValue({ message: errorMessage });
        }
      }
      showErrorToast("Something went wrong.");
      return rejectWithValue("Something went wrong");
    }
  }
);

interface Filter {
  values?: string[];
  search?: string;
  filter?: {
    Accounts?: boolean;
    Compliant?: undefined;
    Devices?: boolean;
    Employees?: boolean;
    "Non-Technical"?: boolean;
    "Not Compliant"?: boolean;
    OWNER?: string;
  };
}

export const setControlFilter = createAction("controls/setControlFilter", (filters?: Filter) => ({
  payload: {
    filters,
  },
}));

export const updateControlClassification = createAsyncThunk(
  "controls/updateControlClassification",
  async (args: { payload: { controlUUID: string; classification: number } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ControlsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let controls = {};
    try {
      const { payload } = args;
      const response = await api.controlsControlUUIDChangedataclassificationClassificationPut(
        payload.controlUUID,
        payload.classification
      );
      controls = response.data ?? {};
    } catch {
      controls = {};
    }
    return controls;
  }
);

export const updateControlOwner = createAsyncThunk(
  "controls/updateControlOwner",
  async (args: { payload: { controlUUID: string; ownerUUID: string } }, { getState }) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ControlsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let ownerData = {};
    try {
      const { payload } = args;
      const response = await api.controlsControlUUIDChangeOwnerOwnerUUIDPut(
        payload.controlUUID,
        payload.ownerUUID
      );
      ownerData = response.data ?? {};
    } catch {
      ownerData = {};
    }
    return ownerData;
  }
);

export const updateControlApplicable = createAsyncThunk(
  "controls/updateControlApplicable",
  async (
    args: { payload: { controlUUID: string; applicable: boolean; reason?: string } },
    { getState }
  ) => {
    const accessToken = `${(getState() as RootState).application.currentUser.userToken}`;
    const api = new ControlsApi(
      { accessToken } as Configuration,
      process.env.NEXT_PUBLIC_MYCIO_API
    );
    let result = {};
    try {
      const { payload } = args;
      const response = await api.controlsControlUUIDApplicablePut(payload.controlUUID, {
        Applicable: payload.applicable,
        ApplicableReason: payload.reason,
      });
      result = response.data ?? {};
    } catch {
      result = {};
    }
    return result;
  }
);

export const selectControlsGivenType = (
  state: RootState,
  type: string,
  subType: string | undefined
) =>
  state.controls.value.filter((control) => {
    if (
      control.AssetType === type &&
      (typeof subType === "undefined" || control.AssetSubType === subType)
    )
      return true;
    return false;
  });

export const controlsSlice = createSlice({
  name: "controls",
  initialState: {
    loading: false,
    value: [] as ControlsWithResources,
    controls: {} as SkeldusControlsResponse,
    control: {
      isLoading: false,
      isEvidencesLoading: false,
      isAssetsLoading: false,
      evidences: {} as ControlsIdEvidencesGet200Response,
      assets: {} as ControlsIdAssetsGet200Response,
      value: {} as ControlDetails,
    },
    filters: {
      values: [],
      search: "",
      filter: {},
    } as Filter,
    updateControlClassificationIsLoading: false,
    updateControlOwnerIsLoading: false,
    updateControlApplicableIsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getControls.pending, (state) => {
        state.loading = true;
      })
      .addCase(getControls.fulfilled, (state, action) => {
        state.controls = action.payload;
        state.loading = false;
      })
      .addCase(getControls.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getControlById.pending, (state) => {
        state.control.isLoading = true;
      })
      .addCase(getControlById.fulfilled, (state, action) => {
        state.control.value = action.payload;
        state.control.isLoading = false;
      })
      .addCase(getControlById.rejected, (state) => {
        state.control.isLoading = false;
      })
      .addCase(getControlByIdAssets.pending, (state) => {
        state.control.isAssetsLoading = true;
      })
      .addCase(getControlByIdAssets.fulfilled, (state, action) => {
        state.control.assets = action.payload;
        state.control.isAssetsLoading = false;
      })
      .addCase(getControlByIdAssets.rejected, (state) => {
        state.control.isAssetsLoading = false;
      })
      .addCase(getControlByIdEvidences.pending, (state) => {
        state.control.isEvidencesLoading = true;
      })
      .addCase(getControlByIdEvidences.fulfilled, (state, action) => {
        state.control.evidences = action.payload;
        state.control.isEvidencesLoading = false;
      })
      .addCase(getControlByIdEvidences.rejected, (state) => {
        state.control.isEvidencesLoading = false;
      })
      .addCase(setControlFilter, (state, action) => {
        if (action.payload.filters?.values) state.filters.values = action.payload.filters?.values;
        if (isString(action.payload.filters?.search))
          state.filters.search = action.payload.filters?.search;
      })
      .addCase(updateControlClassification.fulfilled, (state) => {
        state.updateControlClassificationIsLoading = false;
      })
      .addCase(updateControlClassification.pending, (state) => {
        state.updateControlClassificationIsLoading = true;
      })
      .addCase(updateControlClassification.rejected, (state) => {
        state.updateControlClassificationIsLoading = false;
      })
      .addCase(updateControlOwner.fulfilled, (state) => {
        state.updateControlOwnerIsLoading = false;
      })
      .addCase(updateControlOwner.pending, (state) => {
        state.updateControlOwnerIsLoading = true;
      })
      .addCase(updateControlApplicable.fulfilled, (state) => {
        state.updateControlApplicableIsLoading = false;
      })
      .addCase(updateControlApplicable.pending, (state, action) => {
        state.updateControlApplicableIsLoading = true;
        const updatedControl = state.value.findIndex(
          (control) => control.UUID === action.meta.arg.payload.controlUUID
        );
        state.value[updatedControl].Applicable = action.meta.arg.payload.applicable;
      })
      .addCase(updateControlApplicable.rejected, (state) => {
        state.updateControlApplicableIsLoading = false;
      })
      .addCase("application/logout", (state) => {
        state.value = [];
        state.controls = {};
        state.control = {
          isLoading: false,
          isEvidencesLoading: false,
          isAssetsLoading: false,
          evidences: {} as ControlsIdEvidencesGet200Response,
          assets: {} as ControlsIdAssetsGet200Response,
          value: {} as ControlDetails,
        };
        state.filters = {};
      });
  },
});

export default controlsSlice.reducer;

export const selectControls = (state: RootState) => {
  controlFilter.options[2].selectData = [
    { id: 0, label: "All" },
    ...new Set(
      state.users.users.users?.map((user) => ({
        label: user.email || user.name || "",
        id: user.id,
      }))
    ),
  ];

  const controls = state.controls.controls;

  return controls;
};

export const selectControlsLoading = (state: RootState) => state.controls.loading;
export const selectUpdateControlClassificationIsLoading = (state: RootState) =>
  state.controls.updateControlClassificationIsLoading;
export const selectUpdateControlOwnerIsLoading = (state: RootState) =>
  state.controls.updateControlOwnerIsLoading;
export const selectUpdateControlApplicableIsLoading = (state: RootState) =>
  state.controls.updateControlApplicableIsLoading;
export const selectControlFilters = (state: RootState) => state.controls.filters.values || [];
export const selectControlSearch = (state: RootState) => state.controls.filters.search || "";
export const selectControl = (state: RootState, controlUUID: string) =>
  state.controls.value.find((control) => control.UUID === controlUUID) || {};

export const selectFilters = (state: RootState) => state.controls.filters;
